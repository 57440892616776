
import Vue from 'vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import { RadioGroup, RadioGroupItem } from 'shared/radio-group';
import UIButton from 'shared/ui/buttons/Button.vue';
import UISearch from 'shared/ui/forms/Search.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import { CompactAttendance } from '../shared/CompactAttendance';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import omitBy from 'lodash/omitBy';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import toLower from 'lodash/toLower';
import has from 'lodash/has';
import camelCase from 'lodash/camelCase';
import merge from 'lodash/merge';
import isString from 'lodash/isString';
import Loading from 'shared/components/Loading.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import Avatar from 'shared/components/avatar';
import TeenProfile from 'shared/components/TeenProfile';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { library } from '@fortawesome/fontawesome-svg-core';
import QueryString from 'query-string';
import {
  graduationYears,
  getGrade
} from 'shared/util';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import { AttendanceStatus, GetAttendancesQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Attendance = ArrayElement<GetAttendancesQuery['event']['Attendances']>
type Chapter = Attendance['Teen']['Chapter']
type School = Attendance['Teen']['School']

library.add(faIndent, faOutdent);

export interface Grade {
  year: number | null;
  grade: string;
}
interface Filters {
  chapter: number | number[];
  grade: number | number[];
  school: number | number[];
  gender: number | null;
  includePreRegistered: boolean | null;
}

interface Data {
  term: string | null;
  selectedAttendance: CompactAttendance | null;
  profileClose: boolean;
  filters: Filters & { [key: string]: any };
}

interface Props {
  attendances: Attendance[];
  eventDate: string | null;
  eventTimezone: string | null;
  isLoading: boolean;
  eventId: number;
  router: {
    history: { replace: (arg: any) => void };
    location: { pathname: string; search: string };
  };
}

interface Computed {
  computedFilters: Filters;
  queryStringFilters: string;
  parsedQueryStringFilters: {[key: string]: any};
  graduationYears: Grade[];
  hasAttendances: boolean;
  chapters: Chapter[];
  schools: School[];
  filteredAttendances: CompactAttendance[];
  compactAttendances: CompactAttendance[];
}

interface Methods {
  getGrade: (year: number | null, includeYearInDescription: boolean, schoolYear: number | null) => Grade;
  closeProfile: () => void;
  extractValuesByKey: (collection: any | any[], key: string) => any[];
  setFilter: (x: keyof Filters, y: Filters[typeof x]) => void;
  getValueFor: (collection: any[], value: string, key: string) => any[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Attendance',
  components: {
    UISearch,
    Avatar,
    FilterGroup,
    UIButton,
    RadioGroup,
    RadioGroupItem,
    BorderedList,
    UIRadio,
    Loading,
    PanelsSidebar,
    UICheckbox,
    PanelsGrid,
    ScrollablePanel,
    TeenProfile,
    UISelect,
  },
  directives: {
    fullheight,
  },
  data() {
    return {
      term: null,
      selectedAttendance: null,
      profileClose: false,
      filters: {
        chapter: [],
        school: [],
        grade: [],
        gender: null,
        includePreRegistered: null
      }
    }
  },
  props: {
    attendances: {},
    isLoading: {},
    eventId: {},
    router: {},
    eventDate: {},
    eventTimezone: {}
  },
  computed: {
    computedFilters(): Filters {
      return merge(this.filters, this.parsedQueryStringFilters);
    },
    queryStringFilters() {
      return QueryString.stringify(omitBy(this.filters, (value) => { return isNil(value) || value === 0 }));
    },
    parsedQueryStringFilters() {
      const str = has(this.router, 'location.search')
        ? this.router.location.search
        : '';
      const filters = Object.entries(
        QueryString.parse(str, { parseNumbers: true })
      ).reduce(
        (collection, [key, value]) => ({
          ...collection,
          ...(has(this.filters, camelCase(key))
            ? { [camelCase(key)]: isString(value) ? toLower(value) : value }
            : {}),
        }),
        {}
      ) as {[key: string]: any};
      return filters;
    },
    graduationYears() {
      return graduationYears()
        .map((x) => x.grade)
        .filter((x) =>
          this.compactAttendances.map((a) => a.graduationYear).includes(x.year)
        );
    },
    hasAttendances() {
      return this.attendances.length > 0;
    },
    chapters() {
      return uniqBy(
        compact(this.attendances.map((x) => x.Teen.Chapter)),
        (x) => x.chapterId
      );
    },
    schools() {
      return uniqBy(
        compact(this.attendances.map((x) => x.Teen.School)),
        (x) => x.schoolID
      );
    },
    filteredAttendances() {
      const {
        school,
        chapter,
        grade,
        gender,
        includePreRegistered
      } = this.computedFilters;

      let genderPredicate: (r: CompactAttendance) => boolean;
      switch (gender) {
        case null:
          genderPredicate = (g) => true;
          break;
        case 0:
          genderPredicate = (g) => g.gender === 0;
          break;
        case 1:
          genderPredicate = (g) => g.gender === 1;
          break;
        default:
          genderPredicate = (g) => true;
          break;
      }

      const searchPredicate: (r: CompactAttendance) => boolean = (x) =>
        `${x.firstName.toLowerCase()} ${x.lastName.toLowerCase()}`.indexOf(
          (this.term || '').toLowerCase()
        ) >= 0;
      const gradePredicate = (x: CompactAttendance) =>
        isArray(grade)
          ? grade.length
            ? grade.includes(x.graduationYear || 0)
            : true
          : grade === x.graduationYear;
      const chapterPredicate = (x: CompactAttendance) =>
        isArray(chapter)
          ? chapter.length
            ? chapter.includes(x.chapterId || 0)
            : true
          : chapter === x.chapterId;
      const schoolPredicate = (x: CompactAttendance) =>
        isArray(school)
          ? school.length
            ? school.includes(x.schoolId || 0)
            : true
          : school === x.schoolId;
      const includePreRegisteredPredicate = (x: CompactAttendance) =>
        includePreRegistered
          ? true
          : x.status === AttendanceStatus.Attended;

      return sortBy(
        this.compactAttendances
          .filter(searchPredicate)
          .filter(genderPredicate)
          .filter(gradePredicate)
          .filter(chapterPredicate)
          .filter(schoolPredicate)
          .filter(includePreRegisteredPredicate),
        ['lastName']
      );
    },
    compactAttendances(): CompactAttendance[] {
      return this.attendances.map((a) => ({
        personId: a.personID,
        attendanceId: a.attendanceId,
        thumbnail: a.Teen.thumbnail,
        birthDate: a.Teen.birthDate,
        regionName: a.Teen.Region.regionName,
        chapterName: a.Teen.Chapter ? a.Teen.Chapter.chapterName : '',
        chapterId: a.Teen.Chapter ? a.Teen.Chapter.chapterId : null,
        schoolId: a.Teen.School ? a.Teen.School.schoolID : null,
        firstName: a.Teen.Person.firstName || '',
        lastName: a.Teen.Person.lastName || '',
        gender: a.Teen.Person.gender,
        phoneNumber: a.Teen.Person.Phones[0] ? a.Teen.Person.Phones[0].phoneNumber : '',
        street: a.Teen.Person.Addresses[0] ? a.Teen.Person.Addresses[0].street : '',
        street2: a.Teen.Person.Addresses[0] ? a.Teen.Person.Addresses[0].street2 : '',
        city: a.Teen.Person.Addresses[0] ? a.Teen.Person.Addresses[0].city : '',
        state: a.Teen.Person.Addresses[0] ? a.Teen.Person.Addresses[0].state : '',
        email: a.Teen.Person.EmailAddresses[0] ? a.Teen.Person.EmailAddresses[0].email : '',
        status: a.status,
        graduationYear: a.Teen.graduationYear,
      }));
    }
  },
  methods: {
    getGrade,
    closeProfile() {
      this.profileClose === true
        ? (this.profileClose = false)
        : (this.profileClose = true);
    },
    extractValuesByKey(collection: any | any[], key: string) {
      if (typeof collection === 'object' && !Array.isArray(collection)) {
        return collection[key];
      } else if (collection && collection.length) {
        return collection.map((x: any) => x[key]);
      } else {
        return [];
      }
    },
    setFilter(key: string, value: any) {
      this.filters[`${key}`] = value;
      this.router.history.replace({ search: this.queryStringFilters });
    },
    getValueFor(collection: any[], value: string, key: string) {
      if (isArray(value)) {
        return collection.filter((c) =>
          value.includes(c[key] || toLower(c[key]))
        );
      }
      return collection.find((c) => toLower(c[key]) === toLower(value));
    }
  }
})
