import {
  Event,
  Registration,
  Attendance,
  RegistrationGroupedByStatus,
  GenderStatus,
  ChapterGroupedByName,
  CompactDashboardData,
  RegistrationWithMoreData,
  GroupedRegistrationWithMoreData,
  GroupRegistrationWithMoreData,
  GetRegistrationsByMonthAndYear,
  SchoolGroupedBySecondaryType
} from './types';
import { getGrade } from 'shared/util';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import startCase from 'lodash/startCase';
import { formatDate } from 'shared/util';
import { AttendanceStatus, StatusType } from 'shared/generated/graphql-types';

export function toCompactDashboardData (dashboardData: Event | null): CompactDashboardData | null {
  if (!dashboardData) return  null;
  return {
    startDate: dashboardData.startDate || null,
    startDateLocal: dashboardData.startDateLocal,
    buses: dashboardData.Buses.length || null,
    houses: dashboardData.Houses.length || null,
    address1: dashboardData.address1 || null,
    address2: dashboardData.address2 || null,
    eventName: dashboardData.eventName || null,
    totalRegistrations: dashboardData.totalRegistrations,
    totalAttendees: dashboardData.totalAttendees,
    outstanding: dashboardData.outstanding || null,
    paymentReceived: dashboardData.paymentReceived || null,
    totalScholarshipsGiven: dashboardData.totalScholarshipsGiven || null,
    staff: dashboardData.EventStaff.length || null,
    eventTickets: dashboardData.EventTickets,
    chapter: dashboardData.chapter && dashboardData.chapter.chapterName || null,
    eventUrl: dashboardData.eventUrl,
    cost: dashboardData.cost || 0,
    owner: dashboardData.Owner || null,
    currencySymbol: dashboardData.Region.currencySymbol || null,
    timeZone: dashboardData.TimeZone || null,
    chaptersGroupedByName: chaptersGroupedByName(dashboardData),
    grades: grades(dashboardData) || null,
    schoolsGroupedBySecondaryType: schoolsGroupedBySecondaryType(dashboardData) || null,
    ticketsData: ticketsData(dashboardData),
    registrationGroupedByStatus: registrationGroupedByStatus(dashboardData) || null,
    paymentsStatus: paymentsStatus(dashboardData) || null,
    scholarshipsStatus: scholarshipsStatus(dashboardData) || null,
    genderStatus: genderStatus(dashboardData) || null,
    registrationLink: dashboardData.registrationLink || '',
    registrationLinkWhenRegistrationIsClosed: dashboardData.registrationLinkWhenRegistrationIsClosed || '',
    attendanceAppLink: dashboardData.attendanceAppLink || '',
    attendanceAppSeriesLink: dashboardData.attendanceAppSeriesLink || '',
    attendanceAdvisorLink: dashboardData.attendanceAdvisorLink || '',
    attendancePaymentLink: dashboardData.attendancePaymentLink || '',
    isVirtual: dashboardData.isVirtual || false,
    streamLink: dashboardData.streamLink || '',
    zoomMeetingId: dashboardData.zoomMeetingId || '',
    zoomPasscode: dashboardData.zoomPasscode || '',
    zoomPasscodeHashed: dashboardData.zoomPasscodeHashed || '',
    eventImpressions: dashboardData.EventImpressions,
    waiverSignedDigitally: !!(dashboardData.Registrations || []).length && !!(dashboardData.Registrations.find(r => !!r.waiverSignedDigitally)),
    requiresParentalApproval: dashboardData.requiresParentalApproval || false
  };
}

export function chaptersGroupedByName (dashboardData: Event): ChapterGroupedByName {
  const registrationsOrAttendances = (
    dashboardData.registrationRequired
    ?
    dashboardData.Registrations.filter(r => r.status !== StatusType.RefundRequired)
    :
    dashboardData.Attendances.filter(a => a.status === AttendanceStatus.Attended)
  );

  const chapters = (registrationsOrAttendances as  (Array<Registration | Attendance>)).filter(x => x.Teen.Chapter)
  .map(x => x.Teen.Chapter);
  const groupByChapterName = groupBy(chapters, 'chapterName');

  return groupByChapterName as ChapterGroupedByName;
}

export function grades (dashboardData: Event) {
  const registrationsOrAttendances = (
    dashboardData.registrationRequired
    ?
    dashboardData.Registrations.filter(r => r.status !== StatusType.RefundRequired)
    :
    dashboardData.Attendances.filter(a => a.status === AttendanceStatus.Attended)
  );
  return (registrationsOrAttendances as (Array<Registration | Attendance>)).filter(x => x.Teen.graduationYear)
  .map(x => x.Teen.graduationYear).map(x => getGrade(x));
}
export function schoolsGroupedBySecondaryType(dashboardData: Event): SchoolGroupedBySecondaryType {
  const registrationsOrAttendances = (
      dashboardData.registrationRequired
      ?
      dashboardData.Registrations.filter(r => r.status !== StatusType.RefundRequired)
      :
      dashboardData.Attendances.filter(a => a.status === AttendanceStatus.Attended)
  );
  const schools = (registrationsOrAttendances as(Array < Registration | Attendance > )).filter(x => x.Teen.School)
      .map(x => x.Teen.School);
  const groupedBySecondaryType = Object.entries(groupBy(schools, 'secondaryType')).reduce((collection, [secondaryType, schools]) => {
    return {
      ...collection,
      [startCase(secondaryType)]: schools.map(school => ({name: school!.name, id: school!.schoolID}))
    }
  }, {});
  return groupedBySecondaryType as SchoolGroupedBySecondaryType;
}
export function ticketsData (dashboardData: Event) {
  try {
    const registrations = (
        dashboardData.registrationRequired ?
        dashboardData.Registrations.filter(r => r.status !== StatusType.RefundRequired) :
        []
    );
    if (!registrations.length) {
      return {
      seriesTicket: [],
      categories: []
     };
   }
    const registrationsWithMoreData: RegistrationWithMoreData[] = registrations.map(r => ({
      ...r,
      ticketDescription: r.EventTicket && r.EventTicket.Description || '',
      formattedRegistrationDate: formatDate(r.registrationDate, '%Y-%m', dashboardData.TimeZone) || ''
    })).sort((a, b) => new Date(b.registrationDate).getTime() - new Date(a.registrationDate).getTime());
    const groupedByTicketDescription: GroupedRegistrationWithMoreData = groupBy(registrationsWithMoreData, 'ticketDescription');
    const dates: string[] = uniq(registrationsWithMoreData.map(r => r.formattedRegistrationDate));
    const groupByFormattedRegistrationDate: GroupRegistrationWithMoreData = registrations => groupBy(registrations || [], 'formattedRegistrationDate') || {};
    const registrationsByMonthAndYear: GetRegistrationsByMonthAndYear = registrations => dates.map(date => (groupByFormattedRegistrationDate(registrations)[date] || []).length);

    const seriesTicket = Object.entries(groupedByTicketDescription)
    .map(([ticketDescriptionKey, registrations]) => ({
        name: ticketDescriptionKey,
        data: registrationsByMonthAndYear(registrations),
      })
  );
    return {
      seriesTicket,
      categories: dates
    };
  }
  catch (e) {
    console.log(e);
    return {
      seriesTicket: [],
      categories: []
     };
  }
}

export function registrationGroupedByStatus (dashboardData: Event): RegistrationGroupedByStatus | null {
  const registrationGroupedByStatus = (
    dashboardData.registrationRequired
    ?
    dashboardData.Registrations
    :
    []
  );
  const groupsOfRegistrationStatuses = registrationGroupedByStatus.length ? groupBy(registrationGroupedByStatus, 'status') : null;
  return groupsOfRegistrationStatuses as RegistrationGroupedByStatus | null;
}

export function paymentsStatus (dashboardData: Event) {
  const paymentsStatus = (
    dashboardData.registrationRequired
    ?
    dashboardData.Registrations.filter(r => r.status !== StatusType.RefundRequired)
    :
    []
  );
  const result = paymentsStatus.map(x => ({pending: !!x.balance, completed: !x.balance }));
  return {
    pending: result.filter(x => x.pending).length || 0,
    completed: result.filter(x => x.completed).length || 0
  };
}

export function scholarshipsStatus(dashboardData: Event) {
  const scholarshipsStatus = (
    dashboardData.registrationRequired
      ?
      dashboardData.Registrations.filter(r => r.status !== StatusType.RefundRequired)
      :
      []
  );
  const result = scholarshipsStatus.map(x => (
    {
      pending: x.scholarshipRequest !== null && x.scholarshipRequest > 0 && x.scholarshipGrant === null,
      approved: x.scholarshipGrant !== null && x.scholarshipGrant > 0,
      denied: x.scholarshipRequest !== null && x.scholarshipRequest > 0 && x.scholarshipGrant === 0
    })
  );
  return {
    pending: result.filter(x => x.pending).length || 0,
    approved: result.filter(x => x.approved).length || 0,
    denied: result.filter(x => x.denied).length || 0
  };
}

export function genderStatus (dashboardData: Event): GenderStatus {
  const registrationsOrAttendances = (
    dashboardData.registrationRequired
    ?
    dashboardData.Registrations.filter(r => r.status !== StatusType.RefundRequired)
    :
    dashboardData.Attendances.filter(a => a.status === AttendanceStatus.Attended)
  );
  const genderStatus: Array<{ gender: number }> = (registrationsOrAttendances as (Array<Registration | Attendance>)).map(x => ({gender: x.Teen && x.Teen.gender }));
  return {
    boys: genderStatus.filter(x => !x.gender).length || 0,
    girls: genderStatus.filter(x => x.gender).length || 0
  };
}
