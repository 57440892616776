
import { Vue, Prop, Component } from "vue-property-decorator";
import UIButton from 'shared/ui/buttons/Button.vue';
import { EntityUnionViewModel } from '../../shared/EntityUnionViewModel';
import PopupDetails from '../PopupDetails.vue';
import startCase from 'lodash/startCase';
import has from 'lodash/has';
import { EntityUnionEnum, Gender } from "shared/generated/graphql-types";

@Component({
  name: 'PersonPopup',
	components: {
    PopupDetails,
    UIButton
  },
  inheritAttrs: false
})
export default class PersonPopup extends Vue {

    @Prop() duplicate!: EntityUnionViewModel;
    @Prop() owner!: EntityUnionViewModel;
    @Prop({ type: String as() => EntityUnionEnum, required: true }) ownerType!: EntityUnionEnum;
    @Prop() left!: boolean;
    @Prop() narrow!: boolean;
    @Prop() regionId!: number;
    @Prop() router!: { history: { push: (location: string) => void } };

    @Prop() closePopup!: () => void;

    EntityUnionEnum: typeof EntityUnionEnum = EntityUnionEnum

    get regionName () {
      return this.duplicate.region && this.duplicate.region.regionName
    }
    get potentionalAssociation (): boolean {
      return (
        (
          this.owner && this.owner.type === EntityUnionEnum.Teen && (this.duplicate.type === EntityUnionEnum.Mother || this.duplicate.type === EntityUnionEnum.Father)
          ||
          (this.owner && this.owner.type === EntityUnionEnum.Mother || this.owner && this.owner.type === EntityUnionEnum.Father) && this.duplicate.type === EntityUnionEnum.Teen
          ||
          this.computedOwnerType === EntityUnionEnum.Father && this.duplicate.isParent && this.duplicate.gender === Gender.Male
          ||
          this.computedOwnerType === EntityUnionEnum.Mother && this.duplicate.isParent && this.duplicate.gender === Gender.Female
          ||
          this.computedOwnerType  === EntityUnionEnum.Teen && (this.duplicate.type === EntityUnionEnum.Mother || this.duplicate.type === EntityUnionEnum.Father)
        )
        &&
        (
          !!!(this.owner && this.owner.children.find(c => c && c.personID === this.duplicate.id))
          &&
          !!!(this.owner && this.owner.parents.find(p => p && p.personID === this.duplicate.id))
        )
      )
    }
    get computedOwnerType() {
        return this.owner ? this.owner.type : startCase(this.ownerType)
    }
    get populateOption() {
        return has(this.$listeners, 'populate') && this.computedOwnerType === this.duplicate.type
    }
    get associateOption() {
        return has(this.$listeners, 'associate') && this.potentionalAssociation;
    }
    get duplicateType() {
      return this.duplicate && this.duplicate.type;
    }

    get canNavigateToDuplicateProfile() {
      if(this.duplicate.isParent) {
        return this.duplicate.primaryFamilyId !== null;
      }
      return true;
    }
    navigateToDuplicateProfile() {
      if (this.duplicate.isParent) window.open(`/families/${this.duplicate.primaryFamilyId}`, '_blank');
      if (this.duplicate.isTeen)   window.open(`/teens/${this.duplicate.id}`, '_blank');
    }
}
