
import { Vue, Component, Prop } from 'vue-property-decorator';
import { MutateResult } from '@vue/apollo-composable';
import TeenLookup, { CompactTeen } from 'shared/components/TeenLookup';
import UIButton from "shared/ui/buttons/Button.vue";
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import Modal from 'shared/ui/modal';
import FancySelect from 'shared/ui/forms/FancySelect';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import { AddRegistrationMutation, GetEventTicketsForAddRegistrationQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import QuickAddParent from 'shared/components/QuickAddParent';
import UpdateParentInfo from 'shared/components/UpdateParentInfo';
import { TeenViewModel } from 'shared/components/TeenProfile/types';

type EventTicket = ArrayElement<GetEventTicketsForAddRegistrationQuery['event']['EventTickets']>

interface Form {
  teen: CompactTeen;
  eventTicket: EventTicket;
  suppressEmail: boolean;
  parents: boolean; // can be any type, field is used for error only
}

@Component({
  components: {
    TeenLookup,
    UIButton,
    UICheckbox,
    Modal,
    QuickAddParent,
    UpdateParentInfo,
    FancySelect,
    SimpleForm,
    Field,
    Error
  }
})
export default class AddRegistrationForm extends Vue {
  @Prop() regionId!: number;
  @Prop() eventId!: number;
  @Prop() eventStartDate!: string;
  @Prop() eventTickets!: EventTicket[];
  @Prop() registerTeen!: (teenID: number, eventID: number, eventTicketID: number, suppressEmail: boolean, force?: boolean) => MutateResult<AddRegistrationMutation>
  @Prop() registeringTeen!: boolean;
  @Prop() overLimit!: boolean;
  @Prop() errorRegistering!: boolean;
  @Prop() selectedTeenId!: number | null;
  @Prop() selectedTeenLoading!: boolean;
  @Prop() selectedTeen!: TeenViewModel | null;

  searchAlumni = false

  registration = {
    teen: null,
    eventTicket: null,
    suppressEmail: true,
    parents: false
  }

  validate (values: Form) {
    const errors = {} as Partial<{[P in keyof Form]: string}>

    if (!values.teen) errors.teen = 'Please select a teen';

    if (!values.eventTicket) errors.eventTicket = 'Please select a ticket';

    if (new Date().getTime() < new Date(this.eventStartDate).getTime() && !this.selectedTeen?.father && !this.selectedTeen?.mother) errors.parents = 'Please add parent info';

    return errors;
  }

  async doRegisterTeen (values: Form, force?: boolean) {
    const { teen, eventTicket, suppressEmail } = values;
    const result = await this.registerTeen(teen.personID, this.eventId, eventTicket.EventTicketID, suppressEmail, force);

    if (result?.data?.registerTeen!.registration) {
      this.$emit('registered', result.data.registerTeen.registration);
    }
  }

  handleSubmit (callback: SubmitCallbackArgs<Form>) {
    if ('errors' in callback) return;
    this.doRegisterTeen(callback.values);
  }

  close () {
		this.$emit('registered', false);
	}

  handleTeenSelect (teen: CompactTeen) {
    this.$emit('selectedTeenId', teen.personID)
  }
}
