import ScheduleEvent from './ScheduleEvent.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import tz from 'timezone/loaded';
import { Steps } from '../shared/MultistepForm';
import { computed, CreateElement } from 'vue';
import { FormState, EventScheduleInput } from '../shared/types';
import {
  jsu as jsuThumbnail,
  lnl as lnlThumbnail,
  other as otherThumbnail,
  shabbaton as shabbatonThumbnail
} from '../shared/DefaultEventImages';
import { compose, withProps } from 'vue-compose';
import { calculateEventDuration } from '../shared/util';
import { getZoomTimeZone } from 'shared/util';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';

import sendPushNotificationsMutation from 'shared/queries/sendPushNotifications.graphql';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  CreateZoomMeetingDocument,
  CreateZoomMeetingMutation,
  EventInput,
  GetEventSubTypesQuery,
  GetRegionDataQuery,
  MeQuery,
  RecurrenceType,
  ScheduleQueryMutation,
  ScheduleEventMutation,
  UpdateZoomMeetingDocument,
  UpdateZoomMeetingMutation,
  useGetEventSubTypesQuery,
  ScheduleQueryDocument,
  ScheduleQueryMutationVariables,
  ScheduleEventMutationVariables,
  SendPushNotificationMutationVariables,
  SendPushNotificationMutation,
  ScheduleEventDocument,
  CreateZoomMeetingMutationVariables,
  useMeQuery,
  useGetRegionDataQuery
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { useApolloClient } from '@vue/apollo-composable';

type Me = MeQuery['me'];
type AdvisorRegions = ArrayElement<Me['AdvisorRegions']>;
type Region = GetRegionDataQuery['region'];
type GetEventSubTypes = GetEventSubTypesQuery['eventSubTypes'];
type Event = ScheduleEventMutation['scheduleEvent'];
type Series = NonNullable<ScheduleQueryMutation['scheduleSeries']>;

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const eventSubTypesEnhancer = wrapComponent<
  {},
  { eventSubTypes: GetEventSubTypesQuery['eventSubTypes'] }
>(() => {
  const { result } = useGetEventSubTypesQuery();

  return computed(() => ({
    eventSubTypes: result.value?.eventSubTypes || []
  }));
});

function getDefaultStepData(
  eventType: string,
  user: AdvisorRegions & { Staff: Me },
  region: Region
): Steps | null {
  switch (eventType) {
    case 'JSU':
      return {
        school: {},
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            },
            Repeat: 'custom_recurrence',
            RepeatInterval: 1,
            RepeatType: 'week',
            RepeatEndType: 'date'
          }
        },
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        thumbnailAndDescription: {
          data: {
            thumbnail: jsuThumbnail
          }
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
    case 'LNL':
      return {
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            },
            Repeat: 'custom_recurrence',
            RepeatInterval: 1,
            RepeatType: 'week',
            RepeatEndType: 'date'
          }
        },
        school: {},
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        thumbnailAndDescription: {
          data: {
            thumbnail: lnlThumbnail
          }
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
    case 'Other':
      return {
        eventSubType: {},
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            },
            Repeat: null,
            RepeatInterval: 1,
            RepeatType: 'week',
            RepeatEndType: 'date'
          }
        },
        school: {},
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        thumbnailAndDescription: {
          data: {
            thumbnail: otherThumbnail
          }
        },
        registrationAndTickets: {
          data: {}
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
    case 'ShabbatHoliday':
      return {
        eventSubType: {},
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            },
            Repeat: null,
            RepeatInterval: 1,
            RepeatType: 'week',
            RepeatEndType: 'date'
          }
        },
        school: {},
        thumbnailAndDescription: {
          data: {
            thumbnail: otherThumbnail
          }
        },
        registrationAndTickets: {
          data: {}
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
    case 'Shabbaton':
      return {
        eventSubType: {},
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            }
          }
        },
        school: {},
        thumbnailAndDescription: {
          data: {
            thumbnail: shabbatonThumbnail
          }
        },
        registrationAndTickets: {
          data: {}
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
  }
  return null;
}

@Component({
  watch: {
    eventType() {
      this.stepData = getDefaultStepData(this.eventType, this.user, this.region);
    }
  },
  components: {
    ScheduleEvent
  }
})
class ScheduleEventContainer extends Vue {
  eventType: string | null = null;
  stepData: Steps | null = null;
  state: FormState = 'idle';
  eventId: number = -1;
  seriesId: number = -1;

  @Prop() regionId!: number;
  @Prop() region!: Region;
  @Prop() user!: AdvisorRegions & { Staff: Me };
  @Prop() eventSubTypes!: GetEventSubTypes;

  onStepData(stepData: Steps) {
    this.stepData = stepData;
  }
  resetState() {
    this.state = 'idle';
  }
  async handleSubmit(event: EventScheduleInput) {
    this.state = 'submitting';
    try {
      const { client } = useApolloClient()

      let meeting: CreateZoomMeetingMutation['createZoomMeeting'] | null = null;
      if (event.event && event.event.zoomUserId) {
        const eventData: EventInput = event.event;
        let zoomStartDate = eventData.startDate;
        let zoomTimeZone = 'UTC';
        if (getZoomTimeZone(eventData.TimeZone) !== 'UTC') {
          zoomTimeZone = getZoomTimeZone(eventData.TimeZone);
          zoomStartDate = tz(tz(eventData.startDate as string), '%FT%T', zoomTimeZone);
        }
        const meetingInput: CreateZoomMeetingMutationVariables = {
          id: eventData.zoomUserId as string,
          input: {
            start_time: zoomStartDate,
            duration: calculateEventDuration(
              eventData.startDate as string,
              eventData.endDate as string
            ),
            timezone: getZoomTimeZone(eventData.TimeZone),
            topic: eventData.eventName,
            password: eventData.zoomPasscode
          }
        };
        if (event.series && event.series.Repeat) {
          meetingInput.input.type = 8;
          meetingInput.input.start_time = zoomStartDate!.replace(/\.000Z/, '');
          if (event.series.Repeat === 'custom_recurrence') {
            meetingInput.input.recurrence = {
              ...event.RepeatConfig
            };
            if (!event.RepeatConfig!.end_times) {
              meetingInput.input.recurrence.end_date_time = event.series.EndDate.replace(
                /0\.000Z/,
                '1Z'
              );
            }
          }
          if (event.series.Repeat === 'custom_dates') {
            meetingInput.input.recurrence = {
              repeat_interval: 1,
              type: RecurrenceType.Daily,
              end_times: event.dates!.length || 0
            };
          }
        }
        const result = await client.mutate<CreateZoomMeetingMutation>({
          mutation: CreateZoomMeetingDocument,
          variables: meetingInput
        });
        meeting = (result.data && result.data.createZoomMeeting) || null;
        if (
          meeting &&
          meeting.id &&
          event.series &&
          event.series.Repeat === 'custom_dates' &&
          event.dates!.length > 0
        ) {
          for (let index = event.dates!.length - 1; index >= 0; index--) {
            await client.mutate<UpdateZoomMeetingMutation>({
              mutation: UpdateZoomMeetingDocument,
              variables: {
                id: meeting!.id,
                input: {
                  start_time: event.dates![index]!.start,
                  duration: calculateEventDuration(
                    event.dates![index]!.start,
                    event.dates![index]!.end
                  ),
                  occurrence_id: event.dates![index]!.zoomOccurrencyId
                }
              }
            });
          }
        }
      }
      const isFutureEvent =
        new Date(event.event!.startDate as string).getTime() > new Date().getTime();
      if (!isFutureEvent && event.event) {
        event.event.TeensParticipating = null;
      }
      // we do this here to make life easier for staff who maybe hit the toggle by mistake
      if (event.event && !event.event.registrationRequired) {
        event.event.Tickets = [];
      }
      if (event.series) {
        const result = await client.mutate<ScheduleQueryMutation>({
          mutation: ScheduleQueryDocument,
          variables: {
            series: event.series,
            dates: event.dates,
            event: {
              ...event.event,
              regionId: this.regionId,
              zoomMeetingId: (meeting && meeting.id && meeting.id.toString()) || null,
              zoomPasscodeHashed:
                (meeting && meeting.encrypted_password && meeting.encrypted_password.toString()) ||
                null
            }
          } as ScheduleQueryMutationVariables
        });

        if (result.data!.scheduleSeries) {
          const upcomingEvents = (result.data!.scheduleSeries as Series).Events.filter(
            (x) => new Date(x.startDate) > new Date()
          );
          if (upcomingEvents.length) {
            upcomingEvents.sort((a, b) => {
              const dateA = new Date(a.startDate);
              const dateB = new Date(b.startDate);
              return +dateA - +dateB;
            });
          }
          this.eventId =
            (upcomingEvents && upcomingEvents.length && upcomingEvents[0].eventId) || -1;
          this.seriesId = (result.data!.scheduleSeries as Series)!.seriesID;
        }
      } else {
        const result = await client.mutate<ScheduleEvent>({
          mutation: ScheduleEventDocument,
          variables: {
            event: {
              ...event.event,
              regionId: this.regionId,
              zoomMeetingId: (meeting && meeting.id && meeting.id.toString()) || null,
              zoomPasscodeHashed:
                (meeting && meeting.encrypted_password && meeting.encrypted_password.toString()) ||
                null
            }
          } as ScheduleEventMutationVariables
        });

        if (result.data && result.data.scheduleEvent) {
          this.eventId = (result.data.scheduleEvent as Event).eventId;
          this.seriesId = (result.data.scheduleEvent as Event).seriesId || -1;
        }
      }
      const notificationInput: SendPushNotificationMutationVariables = {
        input: {
          application: 'ncsy',
          regionId: this.regionId,
          title: 'Event added',
          body: `New event ${event.event!.eventName} added`,
          additionalData: {
            notificationType: 'EventAdded',
            eventId: this.eventId
          }
        }
      };
      if (event.event!.chapterID) {
        notificationInput.input.chapterId = event.event!.chapterID;
      }

      const isSendPushNotification =
        isFutureEvent && this.stepData && this.stepData.options!.data!.sendPushNotification;

      if (!event.event!.internal && isSendPushNotification) {
        const notification = await client.mutate<SendPushNotificationMutation>({
          mutation: sendPushNotificationsMutation,
          variables: notificationInput
        });
      }
    } catch (e) {
      alert('There was an error. Please try again.');
      this.resetState();
      throw e;
    }
    this.eventType = null;
    this.state = 'submitted';
  }

  render(h: CreateElement) {
    return h(ScheduleEvent, {
      props: {
        stepData: this.stepData,
        eventType: this.eventType,
        eventSubTypes: this.eventSubTypes,
        state: this.state,
        region: this.region,
        eventId: this.eventId,
        seriesId: this.seriesId
      },
      on: {
        eventType: (eventType: string) => {
          this.eventType = eventType;
        },
        stepData: this.onStepData,
        submit: this.handleSubmit,
        state: this.resetState
      }
    });
  }
}
const getCurrentUser = wrapComponent<{ regionId: number }, {}>((props) => {
  const { result } = useMeQuery();

  return computed(() => ({
    user: result.value?.me
      ? (result.value.me.AdvisorRegions || [])
          .filter((ar) => ar.active)
          .map((ar) => {
            const { AdvisorRegions, ...staff } = result.value!.me!;
            return { ...ar, Staff: staff };
          })
          .find((Obj) => Obj.Region.regionId === props.regionId)
      : {}
  }));
});

export default compose(
  withProps(() => ({
    regionId: getCurrentRegion()
  })),
  wrapComponent<{ regionId: number }, { region: GetRegionDataQuery['region'] | null }>((props) => {
    const { result } = useGetRegionDataQuery(computed(() => ({ regionId: props.regionId })));

    return computed(() => ({
      region: result.value?.region || null
    }));
  }),
  getCurrentUser,
  eventSubTypesEnhancer
)(ScheduleEventContainer);
