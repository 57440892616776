
import Vue from 'vue';
import BorderedList from "shared/ui/lists/BorderedList.vue";
import Pagination from 'shared/components/Pagination';
import UIInput from "shared/ui/forms/Input.vue";
import FiltersPanel from './shared/FiltersPanel.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import fullheight from 'shared/directives/fullheight';
import Loading from 'shared/components/Loading.vue';
import { Filters } from './types';
import { ApolloClient } from '@apollo/client';
import merge from 'lodash/merge';
import { GetFinancialsQuery, PermissionEnum, SortType, StatusType } from "shared/generated/graphql-types";
import { Props as FilterPanelProps } from './shared/FilterOptions';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { RouterLink } from 'vue-component-router';
import FinancialList from './FinancialList';
import QueryString from 'query-string';
import camelCase from 'lodash/camelCase';
import omitBy from 'lodash/omitBy';
import has from 'lodash/has';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import toLower from 'lodash/toLower';
import { formatDate } from 'shared/util';

type Event = GetFinancialsQuery['event'];

library.add(faTrash, faTimes);

interface Data {
  showFilters: boolean;
  StatusType: typeof StatusType;
  filters: Filters & { [key: string]: any };
  sort: SortType | null;
  sortDescending: boolean;
  limit: number;
}

interface Props {
  eventId: number;
  event: Event | undefined;
  path: string;
  client: ApolloClient<any>;
  claims: PermissionEnum[];
  router: {
    history: { replace: (arg: any) => void };
    location: { pathname: string; search: string };
  };
}

interface Computed {
  computedFilters: Filters;
  queryStringFilters: string;
  parsedQueryStringFilters: any;
  noRegistration: boolean;
}

interface Methods {
  formatDate: (date: string, timezone: string, format: string) => string;
  setFilter: FilterPanelProps['setFilter'];
  setFilters: FilterPanelProps['setFilters'];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: "Financials",
  components: {
    PanelsSidebar,
    Loading,
    PanelsGrid,
    BorderedList,
    AutoSaveField,
    UIInput,
    FiltersPanel,
    ScrollablePanel,
    FinancialList,
    Pagination,
    RouterLink,
  },
  directives: {
    fullheight
  },
  data() {
    return {
      showFilters: false,
      checkedRegistrations: [],
      StatusType: StatusType,
      filters: {
        paymentStatus: null,
        gender: null,
        scholarship: null,
        registrationStatus: null,
      },
      sort: SortType.Balance,
      sortDescending: true,
      limit: 20,
    };
  },
  props: {
    eventId: {},
    event: {},
    path: {},
    client: {},
    claims: {},
    router: {}
  },
  computed: {
    computedFilters() {
      return merge(this.filters, this.parsedQueryStringFilters);
    },
    queryStringFilters() {
      return QueryString.stringify(omitBy(this.filters, isNil));
    },
    parsedQueryStringFilters() {
      const str = has(this.router, 'location.search') ? this.router.location.search : '';
      return Object.entries(QueryString.parse(str, { parseNumbers: true })).reduce(
        (collection, [key, value]) => ({
          ...collection,
          ...(has(this.filters, camelCase(key))
            ? { [camelCase(key)]: isString(value) ? toLower(value) : value }
            : {})
        }),
        {}
      );
    },
    noRegistration() {
      return !this.event?.Registrations.length;
    },
  },
  methods: {
    formatDate,
    setFilter(key, value) {
      this.filters[key] = value;
      this.router.history.replace({ search: this.queryStringFilters });
    },
    setFilters(filters) {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = filters[key as keyof Filters];
      });
      this.router.history.replace({ search: null });
    },
  },
});
