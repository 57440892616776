
import { Vue, Component, Prop } from 'vue-property-decorator'
import MultistepForm, { StepData, Steps, ValidationResult } from '../../../shared/MultistepForm'
import ShabbatHolidayForm from './Form.vue';
import { ApolloClient } from '@apollo/client';
import set from 'lodash/set'
import DateTimezone from 'date-timezone';
import { ProgressList, ListItem } from 'shared/ui/lists/ProgressList'
import { FormState } from '../../../shared/types'
import { convertTo24HourFormat } from 'shared/util'
import { other as otherThumbnail, shabbatOneg as shabbatOnegThumbnail, shabbatMeal as shabbatMealThumbnail } from '../../../shared/DefaultEventImages';
import { CompactTeen } from 'shared/components/TeenLookup/shared/CompactTeen';
import Description from '../shared/Description.vue';
import { formatDate, formatTime, formatDateAndTime, isEventTYA } from '../shared/methods';
import {
  mapToEventTicketInputs,
  mapToAdditionalEventItemInputs,
  THUMBNAIL_SIZE_LIMIT,
  THUMBNAIL_SIZE_LIMIT_BASE64,
  startTimeBeforeEndTime,
  startTimeBeforeEndTimeParts
  } from '../../../shared/util';
import EmailBuilder from 'shared/components/EmailBuilder';
import { EditEventPayload } from '../../types';
import { EditEventInput, GetEventForEditQuery, GetRegionDataQuery, GetStaffQuery, SeriesOptionsInput } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Staff = ArrayElement<GetStaffQuery['staff']>['Staff']
type Region = GetRegionDataQuery['region']
type EventTicket = ArrayElement<GetEventForEditQuery['event']['EventTickets']>
type AdditionalEventItem = ArrayElement<GetEventForEditQuery['event']['AdditionalEventItems']>

@Component({
  components: {
    MultistepForm,
    ShabbatHolidayForm,
    EmailBuilder,
    ProgressList,
    ListItem,
    Description
  },
  methods: {
    formatDate,
    formatTime,
    formatDateAndTime,
    startTimeBeforeEndTimeParts
  }
})
export default class ShabbatHoliday extends Vue {
  @Prop() client!: ApolloClient<any>;
  @Prop({type: Object}) stepData!: Steps;
  @Prop({type: String}) state!: FormState;
  @Prop() region!: Region;
  @Prop({ default: 'Details' }) main!: string;
  @Prop() eventId!: number;
  @Prop() seriesId!: number;
  @Prop() chapterId!: number;
  @Prop() openDescriptionTab!: () => void;

  deletedTickets: number[] = [];
  trackDeletedTickets(ticketId: number) {
    if(this.deletedTickets.indexOf(ticketId) < 0){
      this.deletedTickets.push(ticketId);
    }
  }

  deletedAdditionalEventItemIds: number[] = [];
  trackDeletedAdditionalEventItems(additionalEventItemId: number) {
    if(this.deletedAdditionalEventItemIds.indexOf(additionalEventItemId) < 0){
      this.deletedAdditionalEventItemIds.push(additionalEventItemId);
    }
  }

  validate (step: string, data: StepData, steps: Steps): ValidationResult {
    const errors = {}

    switch (step) {
      case 'details': {
        if (!data || !data.name) {
          set(errors, 'details.name', 'Please enter a name for the event')
        }
        if (!data || !data.FirstEvent) {
          if (data && data.repeatEvent) {
            set(errors, 'details.FirstEvent', 'Please pick a date for the first event');
          } else {
            set(errors, 'details.FirstEvent', 'Please pick a start date for the event')
          }
        }
        if (!data || !data.LastEvent) {
          if (data && data.repeatEvent) {
            set(errors, 'details.LastEvent', 'Please pick a date for the last event');
          } else {
            set(errors, 'details.LastEvent', 'Please pick an end date for the event')
          }
        }
        if (!data || !data.StartTime) {
          set(errors, 'details.StartTime', 'Please pick a starting time for the event');
        }
        if (!data || !data.EndTime) {
          set(errors, 'details.EndTime', 'Please pick an ending time for the event');
        }
        if(!data || !data.TimeZone) {
          set(errors, 'details.TimeZone', 'Please select a timezone for this event');
        }
        if (data && data.isVirtual) {
          if (data.streamSchedule) {
            if (!data.zoomMeetingId && !data.zoomUser) {
              set(errors, 'details.zoomUser', 'Please select Zoom User');
            }
            if ((data.zoomUser || data.zoomMeetingId) && !data.zoomPasscode) {
              set(errors, 'details.zoomPasscode', 'Please enter passcode for the Zoom event');
            }
          }
        }
        if (data && startTimeBeforeEndTime(data, true) !== -1) {
          set(errors, 'details.startTimeBeforeEndTime', 'The start time has to be before the end time');
        }
        break;
      }
      case 'staffAndChapter': {
        if (!data || !data.primaryStaff) {
          set(errors, 'staffAndChapter.primaryStaff', 'Please select a primary staff member for the event');
        }
        break;
      }
      case 'registrationAndTickets': {
        if (!data || (data && !data.registrationRequired)) {
          break;
        }
        const detailsStep = (steps['details']!.data || {})
        if(data.RegistrationCloseDate && data.RegistrationCloseTime && this.startTimeBeforeEndTimeParts(detailsStep, data.RegistrationCloseDate, detailsStep.FirstEvent, data.RegistrationCloseTime, detailsStep.StartTime) === 1) {
          set(errors, 'registrationAndTickets.registrationCloseDate', 'The registration close date has to be before the event start date');
        }

        if (data && data.registrationRequired && (!data.tickets || (data.tickets && data.tickets.length === 0))) {
          set(errors, 'registrationAndTickets.tickets', 'Please add at least one ticket')
        }

        if(data.tickets) {
          for(let index = 0; index < (data.tickets ||[]).length; index++) {
            const ticket: EventTicket | null = data.tickets[index];
            if (!ticket) {
              continue;
            }

            if(ticket.startDate && ticket.endDate && this.startTimeBeforeEndTimeParts(detailsStep, ticket.startDate, ticket.endDate, {minutes: "1", hours: "1", ampm: 'AM'}, {minutes: "1", hours: "1", ampm: 'AM'}) !== -1){
              set(errors, `${step}.tickets.${index}.startDate`, 'The start date needs to be before the end date');
            }
            if(ticket.startDate && data.RegistrationCloseDate && data.RegistrationCloseTime && this.startTimeBeforeEndTimeParts(detailsStep, ticket.startDate, data.RegistrationCloseDate, {minutes: "1", hours: "1", ampm: 'AM'}, data.RegistrationCloseTime) !== -1){
              set(errors, `${step}.tickets.${index}.startDateRegistrationCloseDate`, 'The start date needs to be before registration closes');
            }
            if(!ticket.Description || ticket.Description.trim().length < 1) {
              set(errors, `${step}.tickets.${index}.description`, 'Ticket needs a description');
            }
            if(!ticket.price && ticket.deposit) {
              set(errors, `${step}.tickets.${index}.deposit`, 'There can not be a deposit without a price');
            }
            if(ticket.price && +ticket.price < 0) {
              set(errors, `${step}.tickets.${index}.negativePrice`, 'The price can be a negative value');
            }
            if(+ticket.price < +ticket.deposit!) {
              set(errors, `${step}.tickets.${index}.price`, 'The deposit has to be less than or equal to the price');
            }
            if(ticket.quantity && ticket.quantity < 1) {
              set(errors, `${step}.tickets.${index}.quantity`, 'The quantity has to be left blank or above 0');
            }
          }
        }

        if(data.additionalEventItems) {
          for(let index = 0; index < (data.additionalEventItems ||[]).length; index++) {
            const additionalEventItem: AdditionalEventItem | null = data.additionalEventItems[index];
            if (!additionalEventItem) {
              continue;
            }

            if(!additionalEventItem.name || additionalEventItem.name.trim().length < 1) {
              set(errors, `${step}.additionalEventItems.${index}.name`, 'Name is required');
            }
            if(!additionalEventItem.price || additionalEventItem.price < 0) {
              set(errors, `${step}.additionalEventItems.${index}.price`, 'Price is required and can not be a negative value');
            }
          }
        }
        break;
      }
      case 'thumbnailAndDescription': {
        if (!data || (data && !data.thumbnail)) {
          break;
        }
        if (data.thumbnail && data.thumbnail.length > THUMBNAIL_SIZE_LIMIT_BASE64) {
          set(errors, `${step}.thumbnail`, `Image should be less then ${THUMBNAIL_SIZE_LIMIT} Mb`)
        }
        break;
      }
      case 'options': {
        if (!data) {
          break;
        }
        if (data && data.cost < 0) {
          set(errors, 'options.cost', 'Cost should be greater than 0')
        }
        break;
      }
    }

    return errors
  }

  submit (steps: {[x: string]: any}) {
    const {
      FirstEvent,
      LastEvent,
      StartTime,
      EndTime,
      TimeZone,
      Repeat,
    } = steps.details

    DateTimezone.setGlobalTimezone(TimeZone);

    const StartDate = new DateTimezone.DateTimezone(
      FirstEvent.year,
      FirstEvent.month - 1,
      FirstEvent.day,
      convertTo24HourFormat(StartTime),
      +StartTime.minutes,
    );

    const EndDate = new DateTimezone.DateTimezone(
      LastEvent.year,
      LastEvent.month - 1,
      LastEvent.day,
      convertTo24HourFormat(EndTime),
      +EndTime.minutes,
    );

    const event = this.getEventFields(steps, StartDate.toISOString(), EndDate.toISOString());

    const series: SeriesOptionsInput = {};
    if (steps.details.convertToSeries) {
      const EndDate = new DateTimezone.DateTimezone(
        FirstEvent.year,
        FirstEvent.month - 1,
        FirstEvent.day,
        convertTo24HourFormat(EndTime),
        +EndTime.minutes,
      );

      const LastDate = new DateTimezone.DateTimezone(
        LastEvent.year,
        LastEvent.month - 1,
        LastEvent.day,
        convertTo24HourFormat(StartTime),
        +StartTime.minutes,
      );
      event.startDate = StartDate.toISOString();
      event.endDate = EndDate.toISOString();
      event.regionID = this.region.regionId;
      series.Repeat = Repeat;
      series.EndDate = LastDate.toISOString();
    }

    const editEventPayload: EditEventPayload = {
      event,
      ticketIdsToDelete: this.deletedTickets,
      additionalEventItemIdsToDelete: this.deletedAdditionalEventItemIds
    }
    if (steps.details.convertToSeries) {
      editEventPayload.series = series;
    }
    this.$emit('submit', editEventPayload);
  }

  getEventFields(steps: { [x:string]: any}, startDate: string, endDate: string) {
    const chapterID = (steps.staffAndChapter.chapter || {}).chapterId;
    const address = steps.details.Address || {};
    const fullStreamLink = steps.details.streamLink
      ? steps.details.streamLink.match(/^http(s?):\/\//) ? steps.details.streamLink : 'https://' + steps.details.streamLink
      : ''

    const {
      RegistrationCloseDate,
      RegistrationCloseTime,
      registrationRequired
    } = steps.registrationAndTickets;

    let registrationCloseDate: string | null = null;
    if (registrationRequired && RegistrationCloseDate) {
      registrationCloseDate = new DateTimezone.DateTimezone(
        RegistrationCloseDate.year,
        RegistrationCloseDate.month - 1,
        RegistrationCloseDate.day,
        convertTo24HourFormat(RegistrationCloseTime || { hours: 12, minutes: '00', ampm: 'AM' }),
        Number(RegistrationCloseTime && RegistrationCloseTime.minutes) || 0
      ).toISOString();
    }

    const eventFields: EditEventInput = {
      eventName: steps.details.name,
      eventSubTypeID: steps.eventSubType.shabbatType ? steps.eventSubType.shabbatType.id : steps.eventSubType.subType.eventSubTypeId,
      chapterID,
      isRegionWide: !chapterID,
      schoolID: ((steps.school || {}).school || {}).schoolID,
      advisorID: steps.staffAndChapter.primaryStaff.staffID,
      AdvisorsParticipating: (steps.staffAndChapter.staff || []).map((s: Staff) => s.staffID),
      TeensParticipating: steps.registrationAndTickets.registrationRequired ? [] : (steps.staffAndChapter.teens || []).map((t: CompactTeen) => t.personID),
      startDate: startDate,
      endDate: endDate,
      image: !this.isThumbnailDefault(steps.thumbnailAndDescription.thumbnail) ? steps.thumbnailAndDescription.thumbnail.replace(/data:image\/.*?;base64,/, '') : null,
      capacity: steps.options.capacity ? Number(steps.options.capacity) : null,
      cost: !steps.registrationAndTickets.registrationRequired && steps.options.cost ? Number(steps.options.cost) : 0,
      trackId: (steps.options.eventTrack || {}).id,
      registrationRequired: steps.registrationAndTickets.registrationRequired,
      requiresParentalApproval: steps.registrationAndTickets.requiresParentalApproval,
      registrationCloseDate: registrationCloseDate,
      scholarshipAvailable: steps.registrationAndTickets.canRequestScholarshipOnline,
      housingFlag: steps.registrationAndTickets.needHousing,
      registrationEnabled: steps.registrationAndTickets.canRegisterOnline,
      partialPaymentsAllowed: steps.registrationAndTickets.partialPayments,
      isSplitPaymentsAllowed: steps.registrationAndTickets.splitPayments,
      splitPaymentsMonths: steps.registrationAndTickets.splitPayments ? Number(steps.registrationAndTickets.splitPaymentsMonths) : undefined,
      Tickets: mapToEventTicketInputs(steps.registrationAndTickets.tickets),
      AdditionalEventItems: mapToAdditionalEventItemInputs(steps.registrationAndTickets.additionalEventItems),
      timeZone: steps.details.TimeZone,
      ncsyWaiverRequired: steps.registrationAndTickets.ncsyWaiverRequired,
		  requireManualRegistrationApproval: steps.registrationAndTickets.requiredManualRegistrationApproval,
		  subtitle: steps.details.subtitle,
      internal: steps.options.hideFromWebAndMobile,
      address1: address.address,
      city: address.city,
      state: address.state,
      zip: address.zipCode,
      NotificationEmails: steps.options.NotificationEmails,
      baseUrl: steps.options.isTYAEvent ? 2 : steps.options.isBGCubedEvent ? 4 : 0,
      isVirtual: !!steps.details.isVirtual,
      streamLink: fullStreamLink,
      zoomUserId: steps.details.zoomUser && steps.details.zoomUser.id || null,
      zoomMeetingId: (steps.details.zoomMeetingId || '').replace(/ /g, '').replace(/-/g, ''),
      zoomPasscode: steps.details.zoomPasscode,
      zoomPasscodeHashed: steps.details.zoomPasscodeHashed
    };

    if (!steps.details.isVirtual) {
      eventFields.streamLink = null;
      eventFields.zoomUserId = null;
      eventFields.zoomMeetingId = null;
      eventFields.zoomPasscode = null;
      eventFields.zoomPasscodeHashed = null;
    } else {
      if (steps.details.streamSchedule) {
        eventFields.streamLink = null;
      } else {
        eventFields.zoomUserId = null;
        eventFields.zoomPasscode = null;
        eventFields.zoomPasscodeHashed = null;
      }
      if (steps.details.zoomMeetingId) {
        eventFields.streamLink = null;
        eventFields.zoomUserId = null;
      }
    }

    return eventFields;
  }

  isThumbnailDefault(thumbnail: string) {
    return !thumbnail || thumbnail === otherThumbnail || thumbnail === shabbatOnegThumbnail|| thumbnail === shabbatMealThumbnail;
  }

  isTYA(props: { getData: (step: string, key: string) => any }) {
    return isEventTYA(props);
  }
}
