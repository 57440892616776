
  import { Vue, Prop, Watch, Component } from "vue-property-decorator";
  import { longDate, b64toBlob } from 'shared/util';
  import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
  import FileSaver from 'file-saver';
  import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
  import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
  import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { CompactDashboardData } from '../../types';
  import qrcode from 'qrcode';
  import { faMars } from '@fortawesome/pro-regular-svg-icons/faMars';
  import { faVenus } from '@fortawesome/pro-regular-svg-icons/faVenus';
  import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
  import { faQuestion } from '@fortawesome/pro-regular-svg-icons/faQuestion';
  import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
  import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
  import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
  import ClipboardCopy from 'shared/ui/clipboard/clipboard.vue';
  import Modal from 'shared/ui/modal/Modal.vue';
  import UIButton from "shared/ui/buttons/Button.vue";
  import UIInput from 'shared/ui/forms/Input.vue';
  import UITextarea from 'shared/ui/forms/Textarea.vue';
  import StaffLookup from 'shared/components/StaffLookup';
  import { ApolloClient } from '@apollo/client';
  import { formatDate } from 'shared/util'
  import ScrollablePanel from 'shared/components/scrollable-panel.vue';
  import fullheight from 'shared/directives/fullheight';
  import { DownloadWaiversDocument, DownloadWaiversQuery, RecordImpressionsMutation } from "shared/generated/graphql-types";
  import { Me } from "shared/types";
import { series } from 'shared/data/series/editSeries';

  type EventImpression = RecordImpressionsMutation['recordImpressions']

	library.add(faCalendarAlt, faMapMarkerAlt,faLink, faIndent, faOutdent,faMars, faVenus, faArrowRight, faQuestion, faPencil)


	@Component({
    name: 'SideBar',
		methods: {
      longDate
    },
    components: {
      ClipboardCopy,
      PanelsGrid,
      Modal,
      UIButton,
      UIInput,
      UITextarea,
      ScrollablePanel,
      StaffLookup
    },
    directives: {
      fullheight
    }
	})
export default class SideBar extends Vue {
    @Prop() eventId!: number;
    @Prop() dashboardData!: CompactDashboardData | null;
    @Prop() registrationRequired!: boolean;
    @Prop() recordImpressions!: (args: { count: number; notes: string }, id: number) => EventImpression;
    @Prop() recordingImpression!: boolean;
    @Prop() user!: Me;
    @Prop() client!: ApolloClient<any>
    @Prop() thumbnail!: string;
    @Prop() seriesId!: number | null;

    profileClose: boolean = false;
    attendanceAppQrCode: string = '';
    attendanceAppSeriesQrCode: string = '';
    attendanceAppPaymentQrCode: string = '';
    showEditEventImpressionModal: boolean = false;
    showAddEventImpressionModal: boolean = false;
    selectedEventImpression: any = null;
    newImpression: any = {};
    downloading: boolean = false;
    editImpression (eventImpression: any) {
      this.selectedEventImpression = {...eventImpression};
      this.showEditEventImpressionModal = true
    }
    get showWaiversLink () {
      return this.registrationRequired && (this.dashboardData && !!this.dashboardData.waiverSignedDigitally)
    }
    async downloadWaiversHandler () {
      this.downloading = true;
      const { data: { downloadWaivers: { data, contentType, fileName } } } = await this.client.query<DownloadWaiversQuery>({
        query: DownloadWaiversDocument,
        variables: {
          eventId: this.eventId
        }
      });
      this.downloading = false;
      FileSaver.saveAs(b64toBlob(data!, contentType!, `data:${contentType};base64,`), fileName!);
    }
    async saveImpressionHandler () {
      const { count, notes, id } = this.selectedEventImpression;
      try {
          await this.recordImpressions({
              count: Number(count),
              notes
          }, id)
          this.selectedEventImpression = null;
          this.showEditEventImpressionModal = false;
      }
      catch (e) {
          console.log(e);
      }
    }
    async createImpressionHandler () {
      const { count, notes } = this.newImpression;
      try {
          await this.recordImpressions({
              count: Number(count),
              notes,
          }, 0)
          this.newImpression = {};
          this.showAddEventImpressionModal = false;
      }
      catch (e) {
          console.log(e);
      }
    }
    /**
     * Returns an indication of when the event based attendance app link is live
     */
    get activeLinks () {
      try {
        if (this.dashboardData && this.dashboardData.startDate) {
          const startDate = new Date(new Date(this.dashboardData.startDate).setDate(new Date(this.dashboardData.startDate).getDate()-1)).toISOString()
          if (!this.dashboardData.isVirtual && new Date(startDate) > new Date(new Date().setDate(new Date().getDate()-1))) {
            return formatDate(startDate, this.timezone || '', '(active on: %I:%M %p on %m/%d)')
          }
          else {
            return ''
          }
        }
        return ''
      }
      catch (e) {
        console.log(e)
        return ''
      }
    }
    get isProduction() {
        return (process.env.NODE_ENV || 'development') === 'production';
    }
    get ownerName() {
        if (!this.dashboardData) return null;
        if (!this.dashboardData.owner) return;
        return `${this.dashboardData.owner.firstName || 'No Owner selected'} ${this.dashboardData.owner && this.dashboardData.owner.lastName || ''}`
    }
    get eventLink() {
      if (!this.dashboardData) return null;
      return this.dashboardData.eventUrl;
    }
    get timezone() {
        if (!this.dashboardData) return null;
        return this.dashboardData.timeZone;
    }
    closeProfile() {
        this.profileClose === true ? this.profileClose = false : this.profileClose = true;
    }
    downloadAttendanceAppQrCode () {
        FileSaver.saveAs(this.attendanceAppQrCode, `${this.eventId}.png`);
    }
    downloadAttendanceAppSeriesQrCode () {
        FileSaver.saveAs(this.attendanceAppSeriesQrCode, `${this.seriesId}-series.png`);
    }
    downloadAttendanceAppPaymentQrCode () {
        FileSaver.saveAs(this.attendanceAppPaymentQrCode, `${this.eventId}-payment.png`);
    }
    @Watch('dashboardData', {immediate: true})
    async dashboardDataWatcher(newData: CompactDashboardData) {
      try {
        if (newData) {
          if (newData.attendanceAppLink) {
            this.attendanceAppQrCode = await qrcode.toDataURL(newData.attendanceAppLink, { scale: 8 }) || ''
          }
          if (newData.attendanceAppSeriesLink) {
            this.attendanceAppSeriesQrCode = await qrcode.toDataURL(newData.attendanceAppSeriesLink, { scale: 8 }) || ''
          }
          if (newData.attendancePaymentLink) {
            this.attendanceAppPaymentQrCode = await qrcode.toDataURL(newData.attendancePaymentLink, { scale: 8 }) || ''
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }
};
