
import Vue from 'vue';
import MultistepForm, { StepData, Steps, ValidationResult } from '../../../shared/MultistepForm';
import ShabbatHolidayForm from './Form.vue';
import { ApolloClient } from '@apollo/client';
import set from 'lodash/set';
import DateTimezone from 'date-timezone';
import { ProgressList, ListItem } from 'shared/ui/lists/ProgressList';
import { FormState, EventScheduleInput } from '../../../shared/types';
import { convertTo24HourFormat, startDateBeforeEndDate } from 'shared/util';
import { ArrayElement, SimpleDate, SimpleTime } from 'shared/util/types';
import {
  other as otherThumbnail,
  shabbatOneg as shabbatOnegThumbnail,
  shabbatMeal as shabbatMealThumbnail,
  tya as tyaThumbnail,
  bgCubed as bgCubedThumbnail
} from '../../../shared/DefaultEventImages';
import { CompactTeen } from 'shared/components/TeenLookup/shared/CompactTeen';
import {
  mapToEventTicketInputs,
  mapToAdditionalEventItemInputs,
  repeatTypes,
  generateCustomDates,
  startTimeBeforeEndTime,
  startTimeBeforeEndTimeParts,
  formatDate,
  formatTime,
  formatDateAndTime,
  THUMBNAIL_SIZE_LIMIT,
  THUMBNAIL_SIZE_LIMIT_BASE64,
  isFutureEvent
} from '../../../shared/util';
import { EventDates, EventInput, GetRegionDataQuery, GetStaffQuery, GetZoomUsersQuery, ScheduleEventMutation } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']
type Staff = ArrayElement<GetStaffQuery['staff']>['Staff']
type EventTicket = ArrayElement<ScheduleEventMutation['scheduleEvent']['EventTickets']>
type AdditionalEventItem = ArrayElement<ScheduleEventMutation['scheduleEvent']['AdditionalEventItems']>

interface Props {
  client: ApolloClient<any>;
  stepData: Steps;
  state: FormState;
  region: Region;
}

interface Methods {
  validate: (step: string, data: StepData, steps: Steps) => ValidationResult;
  startTimeBeforeEndTime: (data: StepData) => number;
  startDateBeforeEndDate: (startDate: SimpleDate, endDate: SimpleDate) => boolean;
  startTimeBeforeEndTimeParts: (data: StepData, firstDateSource: any, secondDateSource: any, firstTimeSource: any, secondTimeSource: any) => number;
  submit: (steps: {[x: string]: any}) => void;
  createEvent: (steps: {[x:string]: any}) => EventScheduleInput;
  createSeries: (steps: {[x:string]: any}) => EventScheduleInput;
  getEventFields: (steps: { [x:string]: any}, startDate: string, endDate: string) => EventInput;
  handleChange: (step: string, key: string, value: any, form: any) => Promise<void>;
  getDates: (steps: {[x: string]: any}) => {StartDate: Date, EndDate: Date};
  formatDate: (FirstEvent: SimpleDate, LastEvent: SimpleDate) => string;
  formatTime: (StartTime: SimpleTime, EndTime: SimpleTime) => string;
  formatDateAndTime: (date: SimpleDate, time: SimpleTime) => string;
  isThumbnailDefault: (thumbnail: string) => boolean;
  isFutureEvent: (startDate?: SimpleDate, startTime?: SimpleTime, timezone?: string) => boolean;
}

export default Vue.extend<{}, Methods, {}, Props>({
  name: 'Shabbatholiday',
  components: {
    MultistepForm,
    ShabbatHolidayForm,
    ProgressList,
    ListItem
  },
  props: {
    client: {},
    stepData: {type: Object},
    state: {type: String as () => FormState},
    region: {},
  },
  methods: {
    startDateBeforeEndDate,
    startTimeBeforeEndTime,
    startTimeBeforeEndTimeParts,
    formatDate,
    formatTime,
    formatDateAndTime,
    isFutureEvent,
    validate (step, data, steps) {
      const errors = {}

      switch (step) {
        case 'eventSubType': {
          if(!data || !data.subType) {
            set(errors, `${step}.subType`, 'Please select a type for this event');
          }
          if(data && data.subType && data.subType.shabbat && !data.shabbatType) {
            set(errors, `${step}.shabbatType`, 'Please specify what kind of Shabbat event is being scheduled');
          }
          break;
        }
        case 'details': {
          if (!data || !data.name) {
            set(errors, 'details.name', 'Please enter a name for the event')
          }
          if (data && data.isVirtual) {
            if (data.streamSchedule) {
              if (!data.zoomUser) {
                set(errors, 'details.zoomUser', 'Please select Zoom User');
              }
              if (!data.zoomPasscode) {
                set(errors, 'details.zoomPasscode', 'Please enter passcode for the Zoom event');
              }
            }
          }
          if (!data || !data.StartTime) {
            set(errors, 'details.StartTime', 'Please pick a starting time for the event');
          }
          if (!data || !data.EndTime) {
            set(errors, 'details.EndTime', 'Please pick an ending time for the event');
          }
          if(!data || !data.TimeZone) {
            set(errors, 'details.TimeZone', 'Please select a timezone for this event');
          }
          if (this.startTimeBeforeEndTime(data) !== -1) {
            set(errors, 'details.startTimeBeforeEndTime', 'The start time has to be before the end time');
          }
          if (data && data.RepeatEvent) {
            if (data && data.Repeat === 'custom_recurrence') {
              if (!data || !data.RepeatInterval || data.RepeatInterval < 1) {
                set(errors, 'details.RepeatInterval', 'Repeat interval should be greater than 0');
              }
              if (!data || !data.RepeatType) {
                set(errors, 'details.RepeatType', 'Please select recurrence type');
              }
              if (data && data.RepeatType === 'week' && !data.RepeatWeekDays) {
                set(errors, 'details.RepeatWeekDays', 'Please select days of week');
              }
              if (data && data.RepeatType === 'month') {
                if (data.RepeatMonthDay > 31) {
                  set(errors, 'details.RepeatMonthDay', 'Day should be less than 31');
                } else if (data.RepeatMonthDay < 1) {
                  set(errors, 'details.RepeatMonthDay', 'Day should be greater than 0');
                } else if (!data.RepeatMonthDay) {
                  set(errors, 'details.RepeatMonthDay', 'Please enter date of month');
                }
              }
              if (!data || !data.RepeatEndType) {
                set(errors, 'details.RepeatEndType', 'Please select event end type');
              }
              if (data && data.RepeatEndType === 'date') {
                if (!this.startDateBeforeEndDate(data.StartDate, data.LastEvent)) {
                  set(errors, 'details.LastEvent', 'The start date has to be before the end date');
                }
              }
              if (data && data.RepeatEndType === 'occurrence' && data.RepeatEndTimes < 1) {
                set(errors, 'details.RepeatEndTimes', 'Occurrences count should be greater than 0');
              }
            }
            if (data && data.Repeat === 'custom_dates') {
              if (!data || !data.CustomDates || !(data.CustomDates && JSON.parse(data.CustomDates).length > 0)) {
                set(errors, 'details.CustomDates', 'Please select at least one date');
              }
            }
          }
          break;
        }
        case 'staffAndChapter': {
          if (!data || !data.primaryStaff) {
            set(errors, 'staffAndChapter.primaryStaff', 'Please select a primary staff member for the event');
          }
          break;
        }
        case 'registrationAndTickets': {
          if (!data || (data && !data.registrationRequired)) {
            break;
          }
          const detailsStep = (steps['details']!.data || {})
          if(data.RegistrationCloseDate && data.RegistrationCloseTime && this.startTimeBeforeEndTimeParts(detailsStep, data.RegistrationCloseDate, detailsStep.FirstEvent, data.RegistrationCloseTime, detailsStep.StartTime) === 1) {
            set(errors, 'registrationAndTickets.registrationCloseDate', 'The registration close date has to be before the event start date');
          }

          if (data && data.registrationRequired && (!data.tickets || (data.tickets && data.tickets.length === 0))) {
            set(errors, 'registrationAndTickets.tickets', 'Please add at least one ticket')
          }

          if(data.tickets) {
            for(let index = 0; index < (data.tickets ||[]).length; index++) {
              const ticket: EventTicket | null = data.tickets[index];
              if (!ticket) {
                continue;
              }

              if(ticket.startDate && ticket.endDate && this.startTimeBeforeEndTimeParts(detailsStep, ticket.startDate, ticket.endDate, {ampm: 'pm', minutes: 1, hours: 1}, {minutes: 1, hours: 1}) !== -1){
                set(errors, `${step}.tickets.${index}.startDate`, 'The start date needs to be before the end date');
              }
              if(ticket.startDate && data.RegistrationCloseDate && data.RegistrationCloseTime && this.startTimeBeforeEndTimeParts(detailsStep, ticket.startDate, data.RegistrationCloseDate, {ampm: 'pm', minutes: 1, hours: 1}, data.RegistrationCloseTime) !== -1){
                set(errors, `${step}.tickets.${index}.startDateRegistrationCloseDate`, 'The start date needs to be before registration closes');
              }
              if(!ticket.Description || ticket.Description.trim().length < 1) {
                set(errors, `${step}.tickets.${index}.description`, 'Ticket needs a description');
              }
              if(!ticket.price && ticket.deposit) {
                set(errors, `${step}.tickets.${index}.deposit`, 'There can not be a deposit without a price');
              }
              if(ticket.price && +ticket.price < 0) {
                set(errors, `${step}.tickets.${index}.negativePrice`, 'The price can be a negative value');
              }
              if(+ticket.price < +ticket.deposit!) {
                set(errors, `${step}.tickets.${index}.price`, 'The deposit has to be less than or equal to the price');
              }
              if(ticket.quantity && ticket.quantity < 1) {
                set(errors, `${step}.tickets.${index}.quantity`, 'The quantity has to be left blank or above 0');
              }
            }
          }

          if(data.additionalEventItems) {
            for(let index = 0; index < (data.additionalEventItems ||[]).length; index++) {
              const additionalEventItem: AdditionalEventItem | null = data.additionalEventItems[index];
              if (!additionalEventItem) {
                continue;
              }

              if(!additionalEventItem.name || additionalEventItem.name.trim().length < 1) {
                set(errors, `${step}.additionalEventItems.${index}.name`, 'Name is required');
              }
              if(!additionalEventItem.price || additionalEventItem.price < 0) {
                set(errors, `${step}.additionalEventItems.${index}.price`, 'Price is required and can not be a negative value');
              }
            }
          }
          break;
        }
        case 'thumbnailAndDescription': {
          if (!data || (data && !data.thumbnail)) {
            break;
          }
          if (data.thumbnail && data.thumbnail.length > THUMBNAIL_SIZE_LIMIT_BASE64) {
            set(errors, `${step}.thumbnail`, `Image should be less then ${THUMBNAIL_SIZE_LIMIT} Mb`)
          }
          break;
        }
        case 'options': {
          if (!data) {
            break;
          }
          if (data && data.cost < 0) {
            set(errors, 'options.cost', 'Cost should be greater than 0')
          }
          break;
        }
      }

      return errors
    },

    async handleChange(step, key, value, form){
      await Vue.nextTick();

      if(step === 'eventSubType' && key === 'subType'){
        if (this.isThumbnailDefault(form.getData('thumbnailAndDescription', 'thumbnail'))){
          form.setData('thumbnailAndDescription', 'thumbnail', otherThumbnail)
          await Vue.nextTick();
        }
      }

      await Vue.nextTick();

      if(step === 'eventSubType' && key === 'shabbatType' && value){
        if(this.isThumbnailDefault(form.getData('thumbnailAndDescription', 'thumbnail'))) {
          if(value.id === 295) {
            form.setData('thumbnailAndDescription', 'thumbnail', shabbatOnegThumbnail)
            await Vue.nextTick();
          } else if (value.id === 304) {
            form.setData('thumbnailAndDescription', 'thumbnail', shabbatMealThumbnail)
            await Vue.nextTick();
          } else {
            form.setData('thumbnailAndDescription', 'thumbnail', otherThumbnail)
            await Vue.nextTick();
          }
        }
      }

      if ((step === 'eventSubType' && key === 'subType') || (step === 'staffAndChapter' && key === 'primaryStaff')) {
        await Vue.nextTick();
        const staff = form.getData('staffAndChapter', 'primaryStaff');
        const eventSubType = form.getData('eventSubType', 'subType');
        if (eventSubType && staff) {
          if (!eventSubType.shabbat) {
            form.setData('details', 'name', `${eventSubType.normalizedId} with ${staff.firstName} ${staff.lastName}`);
            await Vue.nextTick();
          } else {
            form.setData('details', 'name', `Shabbat with ${staff.firstName} ${staff.lastName}`);
            await Vue.nextTick();
          }
        }
      }

      if((step === 'eventSubType' && key === 'subType') || (step === 'details' && ( key === 'StartTime' || key === 'EndTime' || key === 'TimeZone' || key === 'takesPlaceAtNight'))){
        const reduced = Object.entries(form.state.steps as any)
          .reduce((result, [step, data]) => ({
          ...result,
          [step]: (data as any).data
          }), {});
        const { StartDate, EndDate } = this.getDates(reduced);
        form.setData('details', 'CustomDates', JSON.stringify([{ year: StartDate.getFullYear(), month: StartDate.getMonth() + 1, day: StartDate.getDate() }]));
        await Vue.nextTick();

        form.setData('details', 'StartDate', { year: StartDate.getFullYear(), month: StartDate.getMonth() + 1, day: StartDate.getDate() });
        await Vue.nextTick();

        form.setData('details', 'EndDate', { year: EndDate.getFullYear(), month: EndDate.getMonth() + 1, day: EndDate.getDate() });
        await Vue.nextTick();
      }

      if(step === 'staffAndChapter' && key === 'chapter') {
        if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 414) {
          form.setData('thumbnailAndDescription', 'thumbnail', tyaThumbnail);
        } else if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 422) {
          form.setData('thumbnailAndDescription', 'thumbnail', bgCubedThumbnail);
        } else if(form.getData('thumbnailAndDescription', 'thumbnail') === tyaThumbnail || form.getData('thumbnailAndDescription', 'thumbnail') === bgCubedThumbnail) {
          form.setData('thumbnailAndDescription', 'thumbnail', otherThumbnail);
        }
        await Vue.nextTick();
      }
    },

    submit (steps) {
      let requestObject: any;

      if(steps.details.RepeatEvent) {
        requestObject = this.createSeries(steps);
      } else {
        requestObject = this.createEvent(steps);
      }

      this.$emit('submit', requestObject);
    },

    createEvent(steps) {

      const { StartDate, EndDate } = this.getDates(steps);

      const event = this.getEventFields(steps, StartDate.toISOString(), EndDate.toISOString());

      return { event };
    },

    createSeries(steps) {
      const {
        StartTime,
        EndTime,
        Repeat,
      } = steps.details

      let {
        FirstEvent,
        LastEvent
      } = steps.details;

      let { StartDate, EndDate } = this.getDates(steps);

      let customDates = [];
      steps.details.FirstEvent = {
        year: StartDate.getFullYear(),
        month: StartDate.getMonth() + 1,
        day: StartDate.getDay()
      };

      if (Repeat === 'custom_recurrence') {
        customDates = generateCustomDates(steps.details);
      } else if (Repeat === 'custom_dates') {
        customDates = JSON.parse(steps.details.CustomDates);
        FirstEvent = customDates[0];
        LastEvent = customDates[customDates.length - 1];

        StartDate = new DateTimezone.DateTimezone(
          FirstEvent.year,
          FirstEvent.month - 1,
          FirstEvent.day,
          convertTo24HourFormat(StartTime),
          +StartTime.minutes,
        );

        EndDate = new DateTimezone.DateTimezone(
          FirstEvent.year,
          FirstEvent.month - 1,
          FirstEvent.day,
          convertTo24HourFormat(EndTime),
          +EndTime.minutes,
        );
      }

      const LastDate = new DateTimezone.DateTimezone(
        LastEvent.year,
        LastEvent.month - 1,
        LastEvent.day,
        convertTo24HourFormat(StartTime),
        +StartTime.minutes,
      );

      const series: EventScheduleInput = {
        event: this.getEventFields(steps, StartDate.toISOString(), EndDate.toISOString()),
        series: {
          EndDate: LastDate.toISOString(),
          Repeat
        },
        RepeatConfig: {
          type: repeatTypes[steps.details.RepeatType],
          repeat_interval: steps.details.RepeatInterval
        },
        dates: customDates.map((customDate: SimpleDate, index: number) => {
          const date: EventDates = {
            start: new DateTimezone.DateTimezone(
              customDate.year,
              customDate.month - 1,
              customDate.day,
              convertTo24HourFormat(StartTime),
              +StartTime.minutes,
            ).toISOString(),
            end: new DateTimezone.DateTimezone(
              customDate.year,
              customDate.month - 1,
              customDate.day,
              convertTo24HourFormat(EndTime),
              +EndTime.minutes,
            ).toISOString(),
          }
          if (steps.details.zoomUser) {
            if (Repeat === 'custom_recurrence') {
              date.zoomOccurrencyId = new DateTimezone.DateTimezone(
                customDate.year,
                customDate.month - 1,
                customDate.day,
                convertTo24HourFormat(StartTime),
                +StartTime.minutes,
              ).getTime();
            }
            if (Repeat === 'custom_dates') {
              date.zoomOccurrencyId = new DateTimezone.DateTimezone(
                FirstEvent.year,
                FirstEvent.month - 1,
                FirstEvent.day + index,
                convertTo24HourFormat(StartTime),
                +StartTime.minutes,
              ).getTime();
            }
          }
          return date;
        })
      }
      if (steps.details.RepeatType === 'week') {
        series!.RepeatConfig!.weekly_days = steps.details.RepeatWeekDays;
      } else if (steps.details.RepeatType === 'month') {
        series!.RepeatConfig!.monthly_day = steps.details.RepeatMonthDay
      }
      if (steps.details.RepeatEndType === 'date') {
        series!.RepeatConfig!.end_date_time = LastDate.toISOString();
      } else {
        series!.RepeatConfig!.end_times = steps.details.RepeatEndTimes;
      }

      return series;
    },

    getDates(steps) {
      const { StartTime, EndTime, TimeZone, takesPlaceAtNight } = steps.details;

      DateTimezone.setGlobalTimezone(TimeZone);

      let start = new DateTimezone.DateTimezone(steps.eventSubType.subType.day.engDateString);
      let end = new DateTimezone.DateTimezone(steps.eventSubType.subType.day.engDateString);
      if(takesPlaceAtNight){
        start.setDate(start.getDate() - 1);

        if(EndTime && EndTime.ampm.toLowerCase() === 'pm'){
          end.setDate(end.getDate() - 1);
        }
      }

      if (StartTime){
        start.setHours(convertTo24HourFormat(StartTime));
        start.setMinutes(+StartTime.minutes);
      }
      if(EndTime){
        end.setHours(convertTo24HourFormat(EndTime));
        end.setMinutes(+EndTime.minutes);
      }

      return {
        StartDate: start,
        EndDate: end
      }
    },

    getEventFields(steps, startDate, endDate) {
      const chapterID = (steps.staffAndChapter.chapter || {}).chapterId;
      const address = steps.details.Address || {};
      const fullStreamLink = steps.details.streamLink && !steps.details.streamSchedule
        ? steps.details.streamLink.match(/^http(s?):\/\//) ? steps.details.streamLink : 'https://' + steps.details.streamLink
        : null

      const {
        RegistrationCloseDate,
        RegistrationCloseTime,
        registrationRequired
      } = steps.registrationAndTickets;

      let registrationCloseDate: string | null = null;
      if (registrationRequired && RegistrationCloseDate) {
        registrationCloseDate = new DateTimezone.DateTimezone(
          RegistrationCloseDate.year,
          RegistrationCloseDate.month - 1,
          RegistrationCloseDate.day,
          convertTo24HourFormat(RegistrationCloseTime || { hours: 12, minutes: '00', ampm: 'AM' }),
          Number(RegistrationCloseTime && RegistrationCloseTime.minutes) || 0
        ).toISOString();
      }

      const eventFields: EventInput = {
        eventName: steps.details.name,
        EventSubTypeID: steps.eventSubType.shabbatType ? steps.eventSubType.shabbatType.id : steps.eventSubType.subType.eventSubTypeId,
        chapterID,
        isRegionWide: !chapterID,
        schoolID: ((steps.school || {}).school || {}).schoolID,
        advisorID: steps.staffAndChapter.primaryStaff.staffID,
        AdvisorsParticipating: (steps.staffAndChapter.staff || []).map((s: Staff) => s.staffID),
        TeensParticipating: (steps.staffAndChapter.teens || []).map((t: CompactTeen) => t.personID),
        startDate: startDate,
        endDate: endDate,
        description: steps.thumbnailAndDescription.description,
        image: !this.isThumbnailDefault(steps.thumbnailAndDescription.thumbnail) ? steps.thumbnailAndDescription.thumbnail.replace(/data:image\/.*?;base64,/, '') : null,
        capacity: steps.options.capacity ? Number(steps.options.capacity) : null,
        cost: !steps.registrationAndTickets.registrationRequired && steps.options.cost ? Number(steps.options.cost) : 0,
        trackId: (steps.options.eventTrack || {}).id || null,
        registrationRequired: steps.registrationAndTickets.registrationRequired,
        requiresParentalApproval: steps.registrationAndTickets.requiresParentalApproval,
        registrationCloseDate: registrationCloseDate,
        scholarshipAvailable: steps.registrationAndTickets.canRequestScholarshipOnline,
        housingFlag: steps.registrationAndTickets.needHousing,
        registrationEnabled: steps.registrationAndTickets.canRegisterOnline,
        partialPaymentsAllowed: steps.registrationAndTickets.partialPayments,
        isSplitPaymentsAllowed: steps.registrationAndTickets.splitPayments,
        splitPaymentsMonths: steps.registrationAndTickets.splitPayments ? Number(steps.registrationAndTickets.splitPaymentsMonths) : undefined,
        Tickets: mapToEventTicketInputs(steps.registrationAndTickets.tickets),
        AdditionalEventItems: mapToAdditionalEventItemInputs(steps.registrationAndTickets.additionalEventItems),
        TimeZone: steps.details.TimeZone,
        ncsyWaiverRequired: steps.registrationAndTickets.ncsyWaiverRequired,
        requireManualRegistrationApproval: steps.registrationAndTickets.requiredManualRegistrationApproval,
        subtitle: steps.details.subtitle,
        internal: steps.options.hideFromWebAndMobile,
        address1: address.address,
        city: address.city,
        state: address.state,
        zip: address.zipCode,
        baseUrl: steps.options.isTYAEvent ? 2 : steps.options.isBGCubedEvent ? 4 : 0,
        isVirtual: !!steps.details.isVirtual,
        streamLink: fullStreamLink,
        zoomUserId: steps.details.streamSchedule && steps.details.zoomUser && (steps.details.zoomUser as ArrayElement<GetZoomUsersQuery['zoomUsers']>).id || null,
        zoomMeetingId: steps.details.streamSchedule && (steps.details.zoomMeetingId || '').replace(/ /g, '').replace(/-/g, '') || null,
        zoomPasscode: steps.details.streamSchedule && steps.details.zoomPasscode || null
      };

      return eventFields;
    },

    isThumbnailDefault(thumbnail) {
      return !thumbnail || thumbnail === otherThumbnail || thumbnail === shabbatOnegThumbnail|| thumbnail === shabbatMealThumbnail;
    }
  }
})
