import { computed } from 'vue';
import { compose } from 'vue-compose';
import DeleteCollectionModal from './DeleteCollectionModal.vue';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetCustomQuestionsCollectionCountersQuery } from 'shared/generated/graphql-types';

const getCustomQuestionsCollectionCountersEnhancer = wrapComponent<
  Props,
  Pick<Props, 'countersLoading' | 'counters'>
>((props) => {
  const { loading, result } = useGetCustomQuestionsCollectionCountersQuery(
    computed(() => ({
      id: props.collectionToDelete.customQuestionsCollectionId - 0
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    counters: result.value?.customQuestionsCollectionCounters,
    countersLoading: loading.value
  }));
});

export default compose(getCustomQuestionsCollectionCountersEnhancer)(DeleteCollectionModal);
