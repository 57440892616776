import Vue, { computed, VueConstructor } from "vue";
import { compose } from "vue-compose";
import { DataProxy } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";
import { getOptions, normalizeProps } from "shared/util";
import { withRouter } from "vue-component-router";
import CustomQuestionsCollections from "./CustomQuestionsCollections.vue";
import { Props, Filters } from "./types";
import {
  GetCustomQuestionsCollectionsDocument,
  GetCustomQuestionsCollectionsQuery,
  useCloneExistingCollectionMutation,
  useCreateCustomQuestionsCollectionMutation,
  useDeleteCustomQuestionsCollectionMutation,
  useGetCustomQuestionsCollectionsQuery,
} from "shared/generated/graphql-types";
import { wrapComponent } from "shared/apollo-hoc";

export const filtersInit = (eventId:number) => {
  return {
  name: null,
  eventId: eventId
}};

const updateList = (
  props: Props,
  proxy: DataProxy,
  action: (
    data: GetCustomQuestionsCollectionsQuery
  ) => GetCustomQuestionsCollectionsQuery
) => {
  const data = proxy.readQuery<GetCustomQuestionsCollectionsQuery>({
    query: GetCustomQuestionsCollectionsDocument,
    variables: {
      filter: props.filters,
      page: props.currentPage,
      perPage: props.limit,
    },
  });

  if (data?.customQuestionsCollections) {
    const updatedData = action(cloneDeep(data));
    proxy.writeQuery<GetCustomQuestionsCollectionsQuery>({
      query: GetCustomQuestionsCollectionsDocument,
      variables: {
        filter: props.filters,
        page: props.currentPage,
        perPage: props.limit,
      },
      data: updatedData,
    });
  }
};

const getCustomQuestionsCollectionsEnhancer = wrapComponent<
  Props,
  Pick<Props, "listLoading" | "customQuestionsCollections" | "total">
>((props) => {
  const { loading, result } = useGetCustomQuestionsCollectionsQuery(
    computed(() => ({
      filter: {
        ...props.filters,
      },
      page: props.currentPage,
      perPage: props.limit,
    })),
    { fetchPolicy: "cache-and-network" }
  );

  return computed(() => ({
    listLoading: loading.value,
    customQuestionsCollections:
      result.value?.customQuestionsCollections.customQuestionsCollections || [],
    total: result.value?.customQuestionsCollections.total || 0,
  }));
});

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const {
    currentPage,
    limit,
    filters,
    setFilter,
    clearFilters,
    ...propsToUse
  } = props;

  interface Data {
    filters: Filters;
    currentPage: number;
    limit: number;
  }

  return Vue.extend<
    Data,
    Pick<Props, "setFilter" | "clearFilters">,
    {},
    Pick<
      Props,
      | "setFilter"
      | "clearFilters"
      | "limit"
      | "customQuestionsCollections"
      | "listLoading"
    >
  >({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        filters: filtersInit(this.eventId),
        currentPage: 1,
        limit: 30,
      };
    },
    methods: {
      setFilter(key, value) {
        this.filters = {
          ...this.filters,
          [key]: value,
        };
      },
      clearFilters() {
        this.filters = filtersInit(propsToUse);
        this.currentPage = 1;
      },
    },
    render(h) {
      return h(Component, {
        on: {
          ...this.$listeners,
          currentPage: (page: number) => {
            this.currentPage = page;
          },
        },
        props: {
          ...this.$props,
          filters: this.filters,
          currentPage: this.currentPage,
          limit: this.limit,
          setFilter: this.setFilter,
          clearFilters: this.clearFilters,
        },
      });
    },
  });
};

const deleteCustomQuestionsCollectionEnhancer = wrapComponent<
  Props,
  Pick<Props, "removeCustomQuestionsCollection">
>((props) => {
  const { mutate } = useDeleteCustomQuestionsCollectionMutation();

  return computed(() => ({
    removeCustomQuestionsCollection: async (id) =>
      await mutate(
        { id },
        {
          update(proxy) {
            updateList(props, proxy, (data) => {
              const index =
                data.customQuestionsCollections.customQuestionsCollections.findIndex(
                  (x) => x.customQuestionsCollectionId === id
                );
              if (index !== -1) {
                data.customQuestionsCollections.customQuestionsCollections.splice(
                  index,
                  1
                );
                // eslint-disable-next-line no-param-reassign
                data.customQuestionsCollections.total -= 1;
              }

              return data;
            });
          },
        }
      ),
  }));
});

const addCustomQuestionsCollectionEnhancer = wrapComponent<
  Props,
  Pick<Props, "createCustomQuestionsCollection">
>((props) => {
  const { mutate } = useCreateCustomQuestionsCollectionMutation();

  return computed(() => ({
    createCustomQuestionsCollection: async (name, eventId) =>
      mutate(
        { input: { eventId, name } },
        {
          update(proxy, { data: updateData }) {
            if (updateData?.createCustomQuestionsCollection) {
              updateList(props, proxy, (data) => {
                const { customQuestions, ...collection } =
                  updateData.createCustomQuestionsCollection;
                data.customQuestionsCollections.customQuestionsCollections.push(
                  {
                    ...collection,
                    questionsAmount: customQuestions.length,
                  }
                );
                // eslint-disable-next-line no-param-reassign
                data.customQuestionsCollections.total += 1;

                return data;
              });
            }
          },
        }
      ),
  }));
});

const cloneCollectionEnhancer = wrapComponent<
  Props,
  Pick<Props, "cloneCollection">
>((props) => {
  const { mutate } = useCloneExistingCollectionMutation();

  return computed(() => ({
    cloneCollection: async (name, eventId, collectionId) =>
      await mutate(
        { input: { name, eventId, collectionId } },
        {
          update(proxy, { data: updateData }) {
            if (updateData?.cloneExistingCollection) {
              updateList(props, proxy, (data) => {
                const { customQuestions, ...collection } =
                  updateData.cloneExistingCollection;
                data.customQuestionsCollections.customQuestionsCollections.push(
                  {
                    ...collection,
                    questionsAmount: customQuestions.length,
                  }
                );
                // eslint-disable-next-line no-param-reassign
                data.customQuestionsCollections.total += 1;

                return data;
              });
            }
          },
        }
      ),
  }));
});

export default compose(
  withFilters,
  getCustomQuestionsCollectionsEnhancer,
  deleteCustomQuestionsCollectionEnhancer,
  addCustomQuestionsCollectionEnhancer,
  cloneCollectionEnhancer,
  withRouter
)(CustomQuestionsCollections);
