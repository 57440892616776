import { render, staticRenderFns } from "./CustomQuestionsCollectionItem.vue?vue&type=template&id=59e4242c&scoped=true&"
import script from "./CustomQuestionsCollectionItem.vue?vue&type=script&lang=ts&"
export * from "./CustomQuestionsCollectionItem.vue?vue&type=script&lang=ts&"
import style0 from "./CustomQuestionsCollectionItem.vue?vue&type=style&index=0&id=59e4242c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59e4242c",
  null
  
)

export default component.exports