
import Vue from "vue";
import ScrollablePanel from "shared/components/scrollable-panel.vue";
import BorderedList from "shared/ui/lists/BorderedList.vue";
import Loading from "shared/components/Lock-ui.vue";
import NoData from "shared/components/NoData.vue";
import fullheight from "shared/directives/fullheight";
import PanelsGrid from "shared/components/structure/panelsGrid.vue";
import PanelsSidebar from "shared/components/structure/panelsSidebar.vue";
import UISearch from "shared/ui/forms/Search.vue";
import Modal from "shared/ui/modal/Modal.vue";
import { Props, Data, Computed, Methods } from "./types";
import { dateFormat } from "shared/util";
import UIButton from "shared/ui/buttons/Button.vue";
import UIInput from "shared/ui/forms/Input.vue";
import SimpleForm, { Field, Error } from "vue-simpleform";
import FormSection from "shared/ui/forms/FormSection.vue";
import DeleteCollectionModal from "../shared/DeleteCollectionModal";
import Pagination from "shared/components/Pagination";

export default Vue.extend<Data, Methods, Computed, Props>({
  name: "CustomQuestionsCollections",
  data() {
    return {
      addingCustomQuestionsCollection: false,
      cloningExistingCollection: false,
      collectionIdToClone: 0,
      collectionToDelete: null,
      answersLoading: false,
      formObject: {
        name: null,
      },
    };
  },
  components: {
    Loading,
    NoData,
    PanelsGrid,
    ScrollablePanel,
    BorderedList,
    PanelsSidebar,
    UISearch,
    Modal,
    UIButton,
    UIInput,
    SimpleForm,
    Field,
    Error,
    FormSection,
    DeleteCollectionModal,
    Pagination,
  },
  directives: {
    fullheight,
  },
  props: {
    filters: {},
    setFilter: {},
    clearFilters: {},
    currentPage: {},
    limit: {},
    total: {},
    customQuestionsCollections: {},
    eventId: {},
    listLoading: {},
    removeCustomQuestionsCollection: {},
    createCustomQuestionsCollection: {},
    cloneCollection: {},
    router: {},
    path: {},
  },
  computed: {
    totalPages() {
      if (this.limit === this.total) return this.total / this.limit;
      return Math.ceil(this.total / this.limit);
    },
  },
  watch: {
    customQuestionsCollections() {
      const collection = this.customQuestionsCollections.find(
        (x) => x.eventId === this.eventId
      );

      if (collection) {
        this.redirect(collection.customQuestionsCollectionId);
      }
    },
  },
  methods: {
    dateFormat,
    redirect(id) {
      this.router.history.push(`${this.path}/${id}`);
    },
    async submitHandler(args) {
      if ("errors" in args) return;
      if (args.setSubmitting) args.setSubmitting();
      if (this.addingCustomQuestionsCollection) {
        await this.createCustomQuestionsCollection(
          args.values.name,
          this.eventId
        );
      } else {
        await this.cloneCollection(
          args.values.name,
          this.eventId,
          this.collectionIdToClone
        );
      }
      if (args.setSubmitted) args.setSubmitted();
      this.addingCustomQuestionsCollection = this.cloningExistingCollection =
        false;
    },
    openCloneModal(id) {
      this.collectionIdToClone = id;
      this.cloningExistingCollection = true;
    },
    async tryDelete(collection) {
      if (collection.questionsAmount > 0) {
        this.collectionToDelete = collection;
      } else {
        await this.removeCustomQuestionsCollection(collection.customQuestionsCollectionId);
      }
    },
    async handleDeletion(args) {
      if (!this.collectionToDelete) {
        return;
      }
      if ("errors" in args) return;
      if (args.setSubmitting) args.setSubmitting();
      await this.removeCustomQuestionsCollection(this.collectionToDelete.customQuestionsCollectionId);
      if (args.setSubmitted) args.setSubmitted();
      this.collectionToDelete = null;
    },
    pageChange(page: number) {
      this.$emit("currentPage", page);
    },
  },
});
