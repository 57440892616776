import { render, staticRenderFns } from "./JSU.vue?vue&type=template&id=061cb894&scoped=true&"
import script from "./JSU.vue?vue&type=script&lang=ts&"
export * from "./JSU.vue?vue&type=script&lang=ts&"
import style0 from "./JSU.vue?vue&type=style&index=0&id=061cb894&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061cb894",
  null
  
)

export default component.exports