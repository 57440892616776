import TeenProfile from './TeenProfile.vue';
import { withApollo } from 'shared/components/graphql';
import { computed } from 'vue';
import { compose, withData, withProps } from 'vue-compose';
import { useApolloClient } from '@vue/apollo-composable';
import { toCompactTeen, calcTeenUpdate } from './utils';
import { Props, TeenUpdateArgs, TeenViewModel } from './types';
import { markFamilyAsPrimaryEnhancerForSideBar } from 'shared/components/markFamilyAsPrimaryForTeenEnhancer';
import { addParentToFamilyEnhancer } from '../AddParentToFamily';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { currentUserEnhancer } from 'shared/enhancers/currentUserEnhancer';
import { changePasswordEnhancer } from 'shared/enhancers/changePasswordEnhancer';
import { connectParentToTeenEnhancer } from 'shared/enhancers/connectParentToTeenEnhancer';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  CalculateTeenStatisticsQuery,
  ConsentDataInput,
  NotifyParentsMutationVariables,
  useCalculateTeenStatisticsQuery,
  useDeleteProfileImageMutation,
  useEditTeenMutation,
  useGetAttendanceAppLinkForTeenQuery,
  useGetTeenProfileQuery,
  useMarkTeenConsentMutation,
  useNotifyParentsMutation,
  useUploadLiabilityConsentMutation,
  useUploadTeenImageProfileMutation
} from 'shared/generated/graphql-types';

interface NotifyParentsProps {
  notifyParents: (args: NotifyParentsMutationVariables) => void;
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const notifyParentsEnhancer = wrapComponent<Props, NotifyParentsProps>(() => {
  const { loading, mutate } = useNotifyParentsMutation();

  return computed(() => ({
    notifyParents: (args) => mutate({ ...args }),
    sendingParentsNotification: loading.value
  }));
});

type GetTeenProfileProps = Pick<Props, 'loadingTeenProfile' | 'teen'>;
const getTeenProfileEnhancer = wrapComponent<Props, GetTeenProfileProps>((props) => {
  const { loading, result } = useGetTeenProfileQuery(
    computed(() => ({
      personID: props.personID
    }))
  );

  return computed(() => ({
    teen: result.value?.singleTeen ? toCompactTeen(result.value.singleTeen) : undefined,
    loadingTeenProfile: loading.value
  }));
});

interface MarkTeenConsentProp {
  markTeenConsent: (teenId: number, data: ConsentDataInput) => void;
}
const markTeenConsentEnhancer = wrapComponent<Props, MarkTeenConsentProp>(() => {
  const { loading, mutate } = useMarkTeenConsentMutation();

  return computed(() => ({
    markTeenConsent: (teenId, data) => mutate({ teenId, data }),
    markingTeenConsent: loading.value
  }));
});

interface DeleteProfileImageProp {
  deleteProfileImage: (teenId: number, teenCopy: TeenViewModel) => void;
  deletingProfileImage: boolean;
}
const deleteProfileImageEnhancer = wrapComponent<Props, DeleteProfileImageProp>(() => {
  const { loading, mutate } = useDeleteProfileImageMutation();

  return computed(() => ({
    deleteProfileImage: (teenId, teenCopy) =>
      mutate(
        { teenId },
        {
          optimisticResponse: {
            deleteProfileImage: {
              ...teenCopy.original,
              thumbnail: null
            }
          }
        }
      ),
    deletingProfileImage: loading.value
  }));
});

interface TeenStatisticsProps {
  statistics: CalculateTeenStatisticsQuery['singleTeen']['statistics'];
}
const calculateTeenStatisticsEnhancer = wrapComponent<Props, TeenStatisticsProps>((props) => {
  const { result } = useCalculateTeenStatisticsQuery(
    computed(() => ({
      personID: props.personID
    }))
  );

  return computed(() => ({
    statistics: result.value?.singleTeen.statistics || []
  }));
});

const uploadTeenImageEnhancer = wrapComponent<Props, Pick<Props, 'uploadTeenImageProfile'>>(() => {
  const { mutate } = useUploadTeenImageProfileMutation();

  return computed(() => ({
    uploadTeenImageProfile: async ({ personId, image }) => {
      mutate({ personId, image });
    }
  }));
});

const uploadLiabilityConsentEnhancer = wrapComponent<
  Props,
  Pick<Props, 'uploadLiabilityConsent' | 'uploadingLiabilityConsent'>
>(() => {
  const { loading, mutate } = useUploadLiabilityConsentMutation();

  return computed(() => ({
    uploadLiabilityConsent: async ({ personId, signedByGuardian, file, staffId }) => {
      mutate({ personId, signedByGuardian, file, staffId });
    },
    uploadingLiabilityConsent: loading.value
  }));
});

interface UpdateTeenProps {
  updateTeen: (args: TeenUpdateArgs) => void;
}
const updateTeenEnhancer = wrapComponent<Props, UpdateTeenProps>((props) => {
  const { loading, mutate } = useEditTeenMutation();
  const { client } = useApolloClient();

  return computed(() => ({
    updateTeen: async (args) => {
      mutate(
        {
          fieldName: args.fieldName,
          personId: args.personId,
          value: args.value
        },
        {
          optimisticResponse: {
            updateTeen: {
              ...calcTeenUpdate(props.teen.original, args, client)
            }
          }
        }
      );
    },
    updatingTeen: loading.value
  }));
});

const getAttendanceAppLinkForTeenEnhancer = wrapComponent<
  Props,
  Pick<Props, 'attendanceAppLinkForTeen'>
>((props) => {
  const { result } = useGetAttendanceAppLinkForTeenQuery(
    computed(() => ({
      eventId: props.eventId!,
      personId: props.personID
    })),
    { enabled: computed(() => !!props.eventId) }
  );

  return computed(() => ({
    attendanceAppLinkForTeen: result.value?.getAttendanceAppLinkForTeen || ''
  }));
});

export default compose(
  withData({
    showPasswordModal: {
      initialValue: false
    },
    markingFamilyAsPrimary: {
      initialValue: false
    },
    connectingParentToTeen: {
      initialValue: false
    }
  }),
  withProps(() => ({
    regionId: getCurrentRegion()
  })),
  getTeenProfileEnhancer,
  withApollo,
  calculateTeenStatisticsEnhancer,
  uploadTeenImageEnhancer,
  updateTeenEnhancer,
  changePasswordEnhancer,
  markTeenConsentEnhancer,
  currentUserEnhancer,
  notifyParentsEnhancer,
  connectParentToTeenEnhancer,
  uploadLiabilityConsentEnhancer,
  deleteProfileImageEnhancer,
  getAttendanceAppLinkForTeenEnhancer,
  markFamilyAsPrimaryEnhancerForSideBar,
  addParentToFamilyEnhancer
)(TeenProfile);
