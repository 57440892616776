
import Vue from "vue";
import * as RadioGroup from "shared/radio-group";
import UISwitch from "shared/ui/forms/Switch.vue";
import UIInput from "shared/ui/forms/Input.vue";
import UIButton from "shared/ui/buttons/Button.vue";
import Collapsible from "shared/collapsible";
import isEqual from "lodash/isEqual";
import UIRadio from 'shared/ui/forms/Radio.vue';

import TextStringConfiguration from "../TextStringConfiguration";
import SelectConfiguration from "../SelectConfiguration";
import RadioGroupConfiguration from "../RadioGroupConfiguration";

import {
    Props,
    Data,
    Methods,
    Computed,
    defaultQuestion,
    Question,
} from "./types";

export default Vue.extend<Data, Methods, Computed, Props>({
    name: "Question",
    components: {
        ...RadioGroup,
        UIRadio,
        UISwitch,
        UIInput,
        UIButton,
        Collapsible,
        SelectConfiguration,
        TextStringConfiguration,
        RadioGroupConfiguration,
    },
    directives: {},
    data() {
        return {
            question: { ...this.value },
            types: [
                {
                    displayName: "Text Box",
                    name: "TextString",
                },
                {
                    displayName: "Dropdown",
                    name: "Select",
                },
                {
                    displayName: "Radio Buttons",
                    name: "RadioGroup",
                },
            ],
            editedProperties: []
        };
    },
    watch: {
        value: {
            handler() {
                this.question = { ...this.value };
            },
            deep: true
        },
    },
    props: {
        value: {},
        isDeleting: {},
        validations: {}
    },
    computed: {
        isChanged() {
            const fields: (keyof Question)[] = [
                "required",
                "text",
                "type",
                "minLength",
                "maxLength",
                "isMultiSelect",
                "options"
            ];

            return (
                !this.question.isSaved ||
                fields.some(propName => {
                    var res = !isEqual(this.question[propName], this.question.original?.[propName])
                    return res;
                })
            );
        }
    },
    methods: {
        input() {
            this.$emit("input", {
                ...this.question,
                order: this.value.order,
                id: this.value.customQuestionId,
            });
        },
        reset() {
            this.question = this.value.original ? { ...this.value.original } : defaultQuestion();
            this.question.original = { ...this.question };
            this.input();
            this.editedProperties = [];
        },
        remove() {
            this.$emit("remove");
        },
        markProperty(property) {
            if (!this.editedProperties.includes(property)) {
                this.editedProperties.push(property);
            }
            this.input();
        },
        displayErrors(property) {
            return this.editedProperties.includes(property);
        }
    },
});
