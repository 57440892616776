
import Collapsible from 'shared/collapsible'
import Vue from 'vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import { FormState } from '../../shared/types';

interface Props {
  label: string;
  name: string;
  stepNum: string;
  state: FormState;
}

interface Data {
  displayErrors: boolean;
  isConfirm: boolean;
  isCanConfirm: boolean;
}

interface Computed {
  stepsValidation: boolean;
}

interface Methods {
  getData: (field: string) => any;
  setData:(field: string, value: any) => void;
  handleClick: () => void;
  nextStep: () => void;
  cancelHandler: () => void;
  saveHandler: () => void;
  isConfirmCondition: (condition: boolean) => boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Step',
  components: {
    Collapsible,
    UIButton
  },
  props: {
    label: {},
    name: {},
    stepNum: {},
    state: {}
  },
  data() {
    return {
      displayErrors: true,
      isConfirm: false,
      isCanConfirm: false
    }
  },
  computed: {
    stepsValidation () {
      return Object.keys(this.$parent!.steps).filter(s => this.$parent!.steps[s].validations).length > 0;
    }
  },
  methods: {
    getData (field) {
      return this.$parent!.getData(this.name, field);
    },
    setData (field, value) {
      return this.$parent!.setData(this.name, field, value);
    },
    handleClick() {
      if (this.$scopedSlots.confirmation && this.isCanConfirm) {
        this.isConfirm = true;
        if (this.$parent!.finalStep(this.name)) {
          return;
        }
      }
      this.$parent!.complete(this.name, false);
    },
    nextStep() {
      if (!this.$parent!.finalStep(this.name)) {
        this.$parent!.complete(this.name);
      } else {
        this.$parent!.complete(this.name, false);
      }
    },
    cancelHandler() {
      this.isConfirm = false;
    },
    saveHandler() {
      this.isConfirm = false;
      this.nextStep();
    },
    isConfirmCondition(condition) {
      this.isCanConfirm = condition;
      return condition;
    }
  }
})
