
import Vue from 'vue';
import MultistepForm, { StepData, Steps, ValidationResult } from '../../../shared/MultistepForm';
import OtherForm from './Form.vue';
import { ApolloClient } from '@apollo/client';
import set from 'lodash/set';
import DateTimezone from 'date-timezone';
import { ProgressList, ListItem } from 'shared/ui/lists/ProgressList';
import { FormState, EventScheduleInput } from '../../../shared/types';
import { convertTo24HourFormat, startDateBeforeEndDate } from 'shared/util';
import { ArrayElement, SimpleDate, SimpleTime } from 'shared/util/types';
import {
  other,
  tya as tyaThumbnail,
  bgCubed as bgCubedThumbnail,
  jsu as jsuThumbnail,
  pivot as pivotThumbnail
} from '../../../shared/DefaultEventImages';
import { CompactTeen } from 'shared/components/TeenLookup/shared/CompactTeen';
import has from 'lodash/has';
import {
  mapToEventTicketInputs,
  mapToAdditionalEventItemInputs,
  repeatTypes,
  Intervals,
  generateCustomDates,
  formatRepeat,
  formatDate,
  formatTime,
  formatDateAndTime,
  startTimeBeforeEndTime,
  startTimeBeforeEndTimeParts,
  THUMBNAIL_SIZE_LIMIT,
  THUMBNAIL_SIZE_LIMIT_BASE64,
  isFutureEvent,
  repeatInterval
} from '../../../shared/util';
import { EventDates, EventInput, GetRegionDataQuery, GetStaffQuery, GetZoomUsersQuery, ScheduleEventMutation } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']
type Staff = ArrayElement<GetStaffQuery['staff']>['Staff']
type EventTicket = ArrayElement<ScheduleEventMutation['scheduleEvent']['EventTickets']>
type AdditionalEventItem = ArrayElement<ScheduleEventMutation['scheduleEvent']['AdditionalEventItems']>

interface Props {
  client: ApolloClient<any>;
  stepData: Steps;
  state: FormState;
  region: Region;
}

interface Methods {
  has: <T>(object: T, path: string) => boolean;
  validate: (step: string, data: StepData, steps: Steps) => ValidationResult;
  repeatInterval: (props: StepData) => string
  startDateBeforeEndDate: (startDate: SimpleDate, endDate: SimpleDate) => boolean;
  startTimeBeforeEndTime: (data: StepData) => number;
  startTimeBeforeEndTimeParts: (data: StepData, firstDateSource: any, secondDateSource: any, firstTimeSource: any, secondTimeSource: any) => number;
  formatRepeat: (interval: Intervals, startDate: SimpleDate, endDate: SimpleDate) => string;
  submit: (steps: {[x: string]: any}) => void;
  createEvent: (steps: {[x:string]: any}) => EventScheduleInput;
  createSeries: (steps: {[x:string]: any}) => EventScheduleInput;
  getEventFields: (steps: { [x:string]: any}, startDate: string, endDate: string) => EventInput;
  handleChange: (step: string, key: string, value: any, form: any) => Promise<void>;
  formatDate: (FirstEvent: SimpleDate, LastEvent: SimpleDate) => string;
  formatTime: (StartTime: SimpleTime, EndTime: SimpleTime) => string;
  formatDateAndTime: (date: SimpleDate, time: SimpleTime) => string;
  isFutureEvent: (startDate?: SimpleDate, startTime?: SimpleTime, timezone?: string) => boolean;
}

// TODO dedupe
function isThumbnailDefault(thumbnail: string) {
  return (
    thumbnail === other ||
    thumbnail === tyaThumbnail ||
    thumbnail === bgCubedThumbnail ||
    thumbnail === jsuThumbnail ||
    thumbnail === pivotThumbnail
  );
}

export default Vue.extend<{}, Methods, {}, Props>({
  name: 'Other',
  components: {
    MultistepForm,
    OtherForm,
    ProgressList,
    ListItem
  },
  props: {
    client: {},
    stepData: {type: Object},
    state: {type: String as () => FormState},
    region: {},
  },
  methods: {
    formatRepeat,
    formatDate,
    formatTime,
    formatDateAndTime,
    startDateBeforeEndDate,
    startTimeBeforeEndTime,
    startTimeBeforeEndTimeParts,
    isFutureEvent,
    repeatInterval,
    has,
    validate (step, data, steps) {
      const errors = {}

      switch (step) {
        case 'eventSubType': {
          if(!data || !data.subType) {
            set(errors, `${step}.subType`, 'Please select a type for this event');
          }
          break;
        }
        case 'details': {
          if (!data || !data.name) {
            set(errors, 'details.name', 'Please enter a name for the event')
          }
          if (data && data.isVirtual) {
            if (data.streamSchedule) {
              if (!data.zoomUser) {
                set(errors, 'details.zoomUser', 'Please select Zoom User');
              }
              if (!data.zoomPasscode) {
                set(errors, 'details.zoomPasscode', 'Please enter passcode for the Zoom event');
              }
            }
          }
          if (!data || !data.FirstEvent) {
            if (data && data.repeatEvent) {
              if (data.Repeat !== 'custom_dates') {
                set(errors, 'details.FirstEvent', 'Please pick a date for the first event');
              }
            } else {
              set(errors, 'details.FirstEvent', 'Please pick a start date for the event')
            }
          }
          if (!data || !data.LastEvent) {
            if (data && data.repeatEvent) {
              if (data.Repeat !== 'custom_dates') {
                set(errors, 'details.LastEvent', 'Please pick a date for the last event');
              }
            } else {
              set(errors, 'details.LastEvent', 'Please pick an end date for the event')
            }
          }
          if (!data || !data.StartTime) {
            set(errors, 'details.StartTime', 'Please pick a starting time for the event');
          }
          if (!data || !data.EndTime) {
            set(errors, 'details.EndTime', 'Please pick an ending time for the event');
          }
          if(!data || !data.TimeZone) {
            set(errors, 'details.TimeZone', 'Please select a timezone for this event');
          }
          if(data && this.startTimeBeforeEndTimeParts(data, data.FirstEvent, data.LastEvent, data.StartTime, data.EndTime) !== -1) {
            set(errors, 'details.startTimeBeforeEndTime', 'The start time has to be before the end time');
          }
          if (data && data.RepeatEvent && !data.Repeat) {
            set(errors, 'details.Repeat', 'Please select recurrence type');
          }
          if (data && data.RepeatEvent && this.startTimeBeforeEndTime(data) !== -1) {
            set(errors, 'details.startTimeBeforeEndTime', 'The start time has to be before the end time');
          }
          if (data && data.Repeat === 'custom_recurrence') {
            if (!data || !data.RepeatInterval || data.RepeatInterval < 1) {
              set(errors, 'details.RepeatInterval', 'Repeat interval should be greater than 0');
            }
            if (!data || !data.RepeatType) {
              set(errors, 'details.RepeatType', 'Please select recurrence type');
            }
            if (data && data.RepeatType === 'week' && !data.RepeatWeekDays) {
              set(errors, 'details.RepeatWeekDays', 'Please select days of week');
            }
            if (data && data.RepeatType === 'month') {
              if (data.RepeatMonthDay > 31) {
                set(errors, 'details.RepeatMonthDay', 'Day should be less than 31');
              } else if (data.RepeatMonthDay < 1) {
                set(errors, 'details.RepeatMonthDay', 'Day should be greater than 0');
              } else if (!data.RepeatMonthDay) {
                set(errors, 'details.RepeatMonthDay', 'Please enter date of month');
              }
            }
            if (!data || !data.RepeatEndType) {
              set(errors, 'details.RepeatEndType', 'Please select event end type');
            }
            if (data && data.RepeatEndType === 'date') {
              if (!this.startDateBeforeEndDate(data.FirstEvent, data.LastEvent)) {
                set(errors, 'details.LastEvent', 'The start date has to be before the end date');
              }
            }
            if (data && data.RepeatEndType === 'occurrence' && data.RepeatEndTimes < 1) {
              set(errors, 'details.RepeatEndTimes', 'Occurrences count should be greater than 0');
            }
          }
          if (data && data.Repeat === 'custom_dates') {
            if (!data || !data.CustomDates || !(data.CustomDates && JSON.parse(data.CustomDates).length > 0)) {
              set(errors, 'details.CustomDates', 'Please select at least one date');
            }
          }
          break;
        }
        case 'staffAndChapter': {
          if (!data || !data.primaryStaff) {
            set(errors, 'staffAndChapter.primaryStaff', 'Please select a primary staff member for the event');
          }
          break;
        }
        case 'registrationAndTickets': {
          if (!data || (data && !data.registrationRequired)) {
            break;
          }
          const detailsStep = (steps['details']!.data || {})
          if(data.RegistrationCloseDate && data.RegistrationCloseTime && this.startTimeBeforeEndTimeParts(detailsStep, data.RegistrationCloseDate, detailsStep.FirstEvent, data.RegistrationCloseTime, detailsStep.StartTime) === 1) {
            set(errors, 'registrationAndTickets.registrationCloseDate', 'The registration close date has to be before the event start date');
          }

          if (data && data.registrationRequired && (!data.tickets || (data.tickets && data.tickets.length === 0))) {
            set(errors, 'registrationAndTickets.tickets', 'Please add at least one ticket')
          }

          if(data.tickets) {
            for(let index = 0; index < (data.tickets ||[]).length; index++) {
              const ticket: EventTicket | null = data.tickets[index];
              if (!ticket) {
                continue;
              }

              if(ticket.startDate && ticket.endDate && this.startTimeBeforeEndTimeParts(detailsStep, ticket.startDate, ticket.endDate, {minutes: 1, hours: 1}, {minutes: 1, hours: 1}) !== -1){
                set(errors, `${step}.tickets.${index}.startDate`, 'The start date needs to be before the end date');
              }
              if(ticket.startDate && data.RegistrationCloseDate && data.RegistrationCloseTime && this.startTimeBeforeEndTimeParts(detailsStep, ticket.startDate, data.RegistrationCloseDate, {minutes: 1, hours: 1}, data.RegistrationCloseTime) !== -1){
                set(errors, `${step}.tickets.${index}.startDateRegistrationCloseDate`, 'The start date needs to be before registration closes');
              }
              if(!ticket.Description || ticket.Description.trim().length < 1) {
                set(errors, `${step}.tickets.${index}.description`, 'Ticket needs a description');
              }
              if(!ticket.price && ticket.deposit) {
                set(errors, `${step}.tickets.${index}.deposit`, 'There can not be a deposit without a price');
              }
              if(ticket.price && +ticket.price < 0) {
                set(errors, `${step}.tickets.${index}.negativePrice`, 'The price can be a negative value');
              }
              if(+ticket.price < +ticket.deposit!) {
                set(errors, `${step}.tickets.${index}.price`, 'The deposit has to be less than or equal to the price');
              }
              if(ticket.quantity && ticket.quantity < 1) {
                set(errors, `${step}.tickets.${index}.quantity`, 'The quantity has to be left blank or above 0');
              }
            }
          }

          if(data.additionalEventItems) {
            for(let index = 0; index < (data.additionalEventItems ||[]).length; index++) {
              const additionalEventItem: AdditionalEventItem | null = data.additionalEventItems[index];
              if (!additionalEventItem) {
                continue;
              }

              if(!additionalEventItem.name || additionalEventItem.name.trim().length < 1) {
                set(errors, `${step}.additionalEventItems.${index}.name`, 'Name is required');
              }
              if(!additionalEventItem.price || additionalEventItem.price < 0) {
                set(errors, `${step}.additionalEventItems.${index}.price`, 'Price is required and can not be a negative value');
              }
            }
          }
          break;
        }
        case 'thumbnailAndDescription': {
          if (!data || (data && !data.thumbnail)) {
            break;
          }
          if (data.thumbnail && data.thumbnail.length > THUMBNAIL_SIZE_LIMIT_BASE64) {
            set(errors, `${step}.thumbnail`, `Image should be less then ${THUMBNAIL_SIZE_LIMIT} Mb`)
          }
          break;
        }
        case 'options': {
          if (!data) {
            break;
          }
          if (data && data.cost < 0) {
            set(errors, 'options.cost', 'Cost should be greater than 0')
          }
          break;
        }
      }

      return errors
    },

    async handleChange(step, key, value, form) {
      await Vue.nextTick();

      if (step === 'eventSubType' && key === 'subType' && value) {
        form.setData('details', 'series', null);
        await Vue.nextTick();
      }

      if(step === 'details' && key === 'FirstEvent'){
        if(!form.getData('details', 'LastEvent')){
          form.setData('details', 'LastEvent', value);
          await Vue.nextTick();
        }
      }

      if (step === 'details' && key === 'CustomDates' && form.getData('details', 'Repeat') === 'custom_dates') {
        const dates = JSON.parse(form.getData('details', 'CustomDates'));
        if (dates && dates.length > 0) {
          form.setData('details', 'FirstEvent', dates[0]);
          await Vue.nextTick();
          form.setData('details', 'LastEvent', dates[dates.length - 1]);
          await Vue.nextTick();
        }
      }

      if(step === 'staffAndChapter' && key === 'chapter') {
        if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 414) {
          form.setData('thumbnailAndDescription', 'thumbnail', tyaThumbnail);
        } else if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 422) {
          form.setData('thumbnailAndDescription', 'thumbnail', bgCubedThumbnail);
        } else if (isThumbnailDefault(form.getData('thumbnailAndDescription', 'thumbnail'))) {
          form.setData('thumbnailAndDescription', 'thumbnail', other);
        }
        await Vue.nextTick();
      }

      if (step === 'options' && key === 'eventTrack') {
        if (form.getData('options', 'eventTrack')) {
          if (form.getData('options', 'eventTrack').id === 24) {
            form.setData('thumbnailAndDescription', 'thumbnail', jsuThumbnail);
          } else if (form.getData('options', 'eventTrack').id === 31) {
            form.setData('thumbnailAndDescription', 'thumbnail', pivotThumbnail);
          }
        } else {
          /**
           * if non-jsu track selected
           * if current value is default, use chapter kind if we need
           */
          if (isThumbnailDefault(form.getData('thumbnailAndDescription', 'thumbnail'))) {
            if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 414) {
              form.setData('thumbnailAndDescription', 'thumbnail', tyaThumbnail);
            } else if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 422) {
              form.setData('thumbnailAndDescription', 'thumbnail', bgCubedThumbnail);
            } else {
              form.setData('thumbnailAndDescription', 'thumbnail', other);
            }
          }
        }
        await Vue.nextTick();
      }
    },

    submit (steps) {
      let requestObject: any;

      if(steps.details.RepeatEvent) {
        requestObject = this.createSeries(steps);
      } else {
        requestObject = this.createEvent(steps);
      }
      this.$emit('submit', requestObject);
    },

    createEvent(steps) {
      const {
        FirstEvent,
        LastEvent,
        StartTime,
        EndTime,
        TimeZone,
      } = steps.details

      DateTimezone.setGlobalTimezone(TimeZone);

      const StartDate = new DateTimezone.DateTimezone(
        FirstEvent.year,
        FirstEvent.month - 1,
        FirstEvent.day,
        convertTo24HourFormat(StartTime),
        +StartTime.minutes,
      );

      const EndDate = new DateTimezone.DateTimezone(
        LastEvent.year,
        LastEvent.month - 1,
        LastEvent.day,
        convertTo24HourFormat(EndTime),
        +EndTime.minutes,
      );

      const event = this.getEventFields(steps, StartDate.toISOString(), EndDate.toISOString());

      return { event };
    },

    createSeries(steps) {
      const {
        StartTime,
        EndTime,
        Repeat,
        TimeZone,
      } = steps.details

      let {
        FirstEvent,
        LastEvent
      } = steps.details;

      DateTimezone.setGlobalTimezone(TimeZone);

      let customDates = [];

      if (Repeat === 'custom_recurrence') {
        customDates = generateCustomDates(steps.details);
      } else if (Repeat === 'custom_dates') {
        customDates = JSON.parse(steps.details.CustomDates);
        FirstEvent = customDates[0];
        LastEvent = customDates[customDates.length - 1];
      }

      const StartDate = new DateTimezone.DateTimezone(
        FirstEvent.year,
        FirstEvent.month - 1,
        FirstEvent.day,
        convertTo24HourFormat(StartTime),
        +StartTime.minutes,
      );

      const EndDate = new DateTimezone.DateTimezone(
        FirstEvent.year,
        FirstEvent.month - 1,
        FirstEvent.day,
        convertTo24HourFormat(EndTime),
        +EndTime.minutes,
      );

      const LastDate = new DateTimezone.DateTimezone(
        LastEvent.year,
        LastEvent.month - 1,
        LastEvent.day,
        convertTo24HourFormat(StartTime),
        +StartTime.minutes,
      );

      const series: EventScheduleInput = {
        event: this.getEventFields(steps, StartDate.toISOString(), EndDate.toISOString()),
        series: {
          EndDate: LastDate.toISOString(),
          Repeat,
          seriesId: steps.details.series && steps.details.series.seriesID || null
        },
        RepeatConfig: {
          type: repeatTypes[steps.details.RepeatType],
          repeat_interval: steps.details.RepeatInterval
        },
        dates: customDates.map((customDate: SimpleDate, index: number) => {
          const date: EventDates = {
            start: new DateTimezone.DateTimezone(
              customDate.year,
              customDate.month - 1,
              customDate.day,
              convertTo24HourFormat(StartTime),
              +StartTime.minutes,
            ).toISOString(),
            end: new DateTimezone.DateTimezone(
              customDate.year,
              customDate.month - 1,
              customDate.day,
              convertTo24HourFormat(EndTime),
              +EndTime.minutes,
            ).toISOString(),
          }
          if (steps.details.zoomUser) {
            if (Repeat === 'custom_recurrence') {
              date.zoomOccurrencyId = new DateTimezone.DateTimezone(
                customDate.year,
                customDate.month - 1,
                customDate.day,
                convertTo24HourFormat(StartTime),
                +StartTime.minutes,
              ).getTime();
            }
            if (Repeat === 'custom_dates') {
              date.zoomOccurrencyId = new DateTimezone.DateTimezone(
                FirstEvent.year,
                FirstEvent.month - 1,
                FirstEvent.day + index,
                convertTo24HourFormat(StartTime),
                +StartTime.minutes,
              ).getTime();
            }
          }
          return date;
        })
      }
      if (steps.details.RepeatType === 'week') {
        series!.RepeatConfig!.weekly_days = steps.details.RepeatWeekDays;
      } else if (steps.details.RepeatType === 'month') {
        series!.RepeatConfig!.monthly_day = steps.details.RepeatMonthDay
      }
      if (steps.details.RepeatEndType === 'date') {
        series!.RepeatConfig!.end_date_time = LastDate.toISOString();
      } else {
        series!.RepeatConfig!.end_times = steps.details.RepeatEndTimes;
      }

      return series;
    },

    getEventFields(steps, startDate, endDate) {
      const chapterID = (steps.staffAndChapter.chapter || {}).chapterId;
      const address = steps.details.Address || {};
      const fullStreamLink = steps.details.streamLink && !steps.details.streamSchedule
        ? steps.details.streamLink.match(/^http(s?):\/\//) ? steps.details.streamLink : 'https://' + steps.details.streamLink
        : null

      const {
        RegistrationCloseDate,
        RegistrationCloseTime,
        registrationRequired
      } = steps.registrationAndTickets;

      let registrationCloseDate: string | null = null;
      if (registrationRequired && RegistrationCloseDate) {
        registrationCloseDate = new DateTimezone.DateTimezone(
          RegistrationCloseDate.year,
          RegistrationCloseDate.month - 1,
          RegistrationCloseDate.day,
          convertTo24HourFormat(RegistrationCloseTime || { hours: 12, minutes: '00', ampm: 'AM' }),
          Number(RegistrationCloseTime && RegistrationCloseTime.minutes) || 0
        ).toISOString();
      }

      const eventFields: EventInput = {
        eventName: steps.details.name,
        EventSubTypeID: steps.eventSubType.subType.eventSubTypeId,
        chapterID,
        isRegionWide: !chapterID,
        schoolID: ((steps.school || {}).school || {}).schoolID,
        advisorID: steps.staffAndChapter.primaryStaff.staffID,
        AdvisorsParticipating: (steps.staffAndChapter.staff || []).map((s: Staff) => s.staffID),
        TeensParticipating: (steps.staffAndChapter.teens || []).map((t: CompactTeen) => t.personID),
        startDate: startDate,
        endDate: endDate,
        description: steps.thumbnailAndDescription.description,
        image: !isThumbnailDefault(steps.thumbnailAndDescription.thumbnail) ? steps.thumbnailAndDescription.thumbnail.replace(/data:image\/.*?;base64,/, '') : null,
        capacity: steps.options.capacity ? Number(steps.options.capacity) : null,
        cost: !steps.registrationAndTickets.registrationRequired && steps.options.cost ? Number(steps.options.cost) : 0,
        trackId: (steps.options.eventTrack || {}).id || null,
        registrationRequired: steps.registrationAndTickets.registrationRequired,
        requiresParentalApproval: steps.registrationAndTickets.requiresParentalApproval,
        registrationCloseDate: registrationCloseDate,
        scholarshipAvailable: steps.registrationAndTickets.canRequestScholarshipOnline,
        housingFlag: steps.registrationAndTickets.needHousing,
        registrationEnabled: steps.registrationAndTickets.canRegisterOnline,
        partialPaymentsAllowed: steps.registrationAndTickets.partialPayments,
        isSplitPaymentsAllowed: steps.registrationAndTickets.splitPayments,
        splitPaymentsMonths: steps.registrationAndTickets.splitPayments ? Number(steps.registrationAndTickets.splitPaymentsMonths) : undefined,
        Tickets: mapToEventTicketInputs(steps.registrationAndTickets.tickets),
        AdditionalEventItems: mapToAdditionalEventItemInputs(steps.registrationAndTickets.additionalEventItems),
        TimeZone: steps.details.TimeZone,
        ncsyWaiverRequired: steps.registrationAndTickets.ncsyWaiverRequired,
        requireManualRegistrationApproval: steps.registrationAndTickets.requiredManualRegistrationApproval,
        subtitle: steps.details.subtitle,
        internal: steps.options.hideFromWebAndMobile,
        address1: address.address,
        city: address.city,
        state: address.state,
        zip: address.zipCode,
        baseUrl: steps.options.isTYAEvent ? 2 : steps.options.isBGCubedEvent ? 4 : 0,
        seriesId: steps.details.Repeat ? null : steps.details.series && steps.details.series.seriesID,
        isVirtual: !!steps.details.isVirtual,
        streamLink: fullStreamLink,
        zoomUserId: steps.details.streamSchedule && steps.details.zoomUser && (steps.details.zoomUser as ArrayElement<GetZoomUsersQuery['zoomUsers']>).id || null,
        zoomMeetingId: steps.details.streamSchedule && (steps.details.zoomMeetingId || '').replace(/ /g, '').replace(/-/g, '') || null,
        zoomPasscode: steps.details.streamSchedule && steps.details.zoomPasscode || null
      };

      return eventFields;
    },
  }
})
