import Vue, { CreateElement } from 'vue';
import { Filters, ScholarshipStatus } from '../types';
import sortBy from 'lodash/sortBy';
import toLower from 'lodash/toLower';
import { AddPaymentInput, AddPaymentMutation, GetFinancialsQuery, PaymentType, SortType, StatusType, useAddPaymentMutation } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { MutateResult } from '@vue/apollo-composable';

type Event = GetFinancialsQuery['event']
type Registration = ArrayElement<Event['Registrations']>

interface Data {
  currentPage: number;
  processingPayment: boolean;
}

interface Computed {
  paymentPayload: AddPaymentInput;
  filteredRegistrations: Registration[];
  paginatedRegistrations: Registration[];
  totalPages: number;
  total: number;
  loading: boolean;
}

interface Props {
  registrations: Registration[];
  filters: Filters;
  event: Event;
  sortBy: SortType | null;
  sortDescending: boolean;
  limit: number;
}

interface Methods {
  pageChange: (page: number) => void;
  addPayment: (registrationID: number, amount: number) => MutateResult<AddPaymentMutation> | void;
}

type RegistrationPredicate = (r: Registration) => boolean;
type RegistrationFilter<T> = (value: T, e: Event) => RegistrationPredicate;

export const predicates: { [K in keyof Filters]: RegistrationFilter<Filters[K]> } = {
  gender: (value) => (r) => r.Teen.Person.gender === value,
  paymentStatus: (value) => (r) => {
    if (toLower(value as string) === toLower('NoPaymentsMade')) {
      return r.Payments.length === 0
    }
    return r.paymentStatuses.map(toLower).includes(toLower(value || ''))
  },
  registrationStatus: (value) => (r) => toLower(r.status) === toLower(value || ''),
  scholarship: (value) => (r) => {
    const scholarshipStatus: { [key: string]: string } = Object.keys(ScholarshipStatus).reduce(
      (collection, key) => ({ ...collection, [key]: toLower(key) }),
      {}
    );
    switch (toLower(value || '')) {
      case scholarshipStatus.Approved:
        return r.scholarshipGrant !== null && r.scholarshipGrant > 0;
      case scholarshipStatus.Denied:
        return (
          r.scholarshipRequest !== null && r.scholarshipRequest > 0 && r.scholarshipGrant === 0
        );
      case scholarshipStatus.Pending:
        return (
          r.scholarshipRequest !== null && r.scholarshipRequest > 0 && r.scholarshipGrant === null
        );
      default:
        return false;
    }
  },
};

const sortByFunctions: { [k: string]: (r: Registration) => any } = {
  [SortType.Balance]: (r) => r.balance,
  [SortType.Id]: (r) => r.registrationID,
  [SortType.Name]: (r) => r.Teen.Person.lastName,
  [SortType.RegistrationDate]: (r) => r.registrationDate
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FinancialList',
  props: {
    registrations: {},
    filters: {},
    event: {},
    sortBy: {},
    sortDescending: {},
    limit: {},
  },
  data() {
    return {
      currentPage: 1,
      processingPayment: false,
    }
  },
  computed: {
    paymentPayload () {
      return {
        amount: 0,
        type: PaymentType.Cash,
        CCType: null,
        note: '',
        authorizationToken: null,
        checkNumber: null,
        source: null,
        paymentDate: new Date(),
        suppressEmail: false
      };
    },
    filteredRegistrations() {
      const filters: Array<RegistrationPredicate> = [];

      (Object.entries(this.filters) as [keyof Filters, any][]).map(([key, value]) => {
        if (value !== null) {
          const predicate = predicates[key] as RegistrationFilter<any>;
          filters.push(predicate(value, this.event));
        }
      });

      const filteredRegistrations = filters.reduce(
        (registrations, filter) => registrations.filter(filter),
        this.registrations
      );

      if (this.sortBy) {
        const func = sortByFunctions[this.sortBy];

        const registrations = sortBy<Registration>(filteredRegistrations, func);

        if (this.sortDescending) registrations.reverse();

        return registrations;
      }
      
      if (this.filters.paymentStatus) {
        return filteredRegistrations;
      } else {
        return filteredRegistrations.filter(
          (r) => toLower(r.status) !== toLower(StatusType.Cancelled)
        );
      }
    },
    paginatedRegistrations() {
      return this.filteredRegistrations.slice((this.currentPage - 1) * this.limit, (this.currentPage - 1) * this.limit + this.limit);
    },
    total() {
      return this.filteredRegistrations.length;
    },
    totalPages() {
      if (this.limit === this.total) return this.total / this.limit;
      return Math.ceil(this.total / this.limit);
    },
    loading() {
      return this.processingPayment;
    }
  },
  methods: {
    async addPayment(registrationID: number, amount: number) {
      const { mutate } = useAddPaymentMutation();

      this.processingPayment = true;

      try {
        const result = await mutate({ input: { ...this.paymentPayload, amount: Number(amount) }, registrationId: registrationID });
        return result;
      } finally {
        this.processingPayment = false;
      }
    },
    pageChange(page: number) {
      this.currentPage = page
    },
  },
  render(h: CreateElement) {
    const { paginatedRegistrations, currentPage, totalPages, total, limit, pageChange, addPayment, loading } = this;

    const vNode = this.$scopedSlots.default && this.$scopedSlots.default({
      paginatedRegistrations,
      currentPage,
      totalPages,
      total,
      limit,
      pageChange,
      addPayment,
      loading,
    });

    return h('div', vNode);
  }
});
