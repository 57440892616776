import { DocumentNode, FragmentSpreadNode, OperationDefinitionNode } from 'graphql';
import Vue, { Component, ComponentOptions, FunctionalComponentOptions } from 'vue';
import range from 'lodash/range';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import tz from 'timezone/loaded';
import parser from 'another-name-parser';
import PhoneNumber from 'awesome-phonenumber';
import { EmailAddress, Phone, Address, ArrayElement } from './types';
import DateTimezone from 'date-timezone';
import trim from 'lodash/trim';
import { User } from '@sentry/browser';
import ordinal from 'ordinal-numbers';
import { SimpleDate } from './types';
import { GetSingleSeriesQuery, PhoneType } from 'shared/generated/graphql-types';
import capitalize from 'lodash/capitalize';

type Event = ArrayElement<GetSingleSeriesQuery['singleSeries']['Events']>

export interface GraduationYear {
  grade: string;
  year: number | null;
}

type KeyOptions = keyof Pick<Event, 'startDate' | 'endDate'>;
export function getTime(event: Event, source: KeyOptions) {
  DateTimezone.setGlobalTimezone(event.TimeZone);
  const date = new DateTimezone.DateTimezone(event[source]);
  const hour = date.getHours();
  return {
    hours: hour <= 12 ? hour : hour - 12,
    minutes: date.getMinutes().toString().padStart(2, '0'),
    ampm: hour < 12 ? 'AM' : 'PM'
  };
}

export function getDate(event: Event, source: KeyOptions) {
  DateTimezone.setGlobalTimezone(event.TimeZone);
  const date = new DateTimezone.DateTimezone(event[source]);
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  };
}

export const startDateBeforeEndDate = (startDate: SimpleDate | null, endDate: SimpleDate | null) => {
  if (!startDate || !endDate) {
    return false;
  }
  const sDate = new Date(startDate.year, startDate.month - 1, startDate.day).getTime();
  const eDate = new Date(endDate.year, endDate.month - 1, endDate.day).getTime();

  return sDate <= eDate;
}

export const isFutureDate = (date: Date) => {
  if (!date) return
  const today = new Date ().setHours(0, 0, 0, 0);
  return date.setHours(0, 0, 0, 0) > today;
};
export async function getUserInfoFromSessionStorage(): Promise <User> {
  return new Promise((resolve, reject) => {
      try {
          if (sessionStorage && Object.keys(sessionStorage).length) {
              const userIndex = Object.keys(sessionStorage).findIndex(x => x.includes('user'));
              const userInfo = sessionStorage[Object.keys(sessionStorage)[userIndex]];
              if (userInfo) {
                  const {
                      profile: {
                          email,
                          name: username
                      }
                  } = JSON.parse(userInfo);
                  resolve({
                      id: email,
                      username,
                      email
                  });
              } else {
                  reject(null);
              }
          }
      } catch (e) {
          reject(null);
      }
  });
}
export function formatDate(date: string, timezone: string, format: string) {
  return tz(tz(date), format, timezone);
}

export function isValueless(x: any) {
  return x === null || x === undefined;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  const testDummy: TValue = value;
  return true;
}
export function validateName (name: string) {
  const { firstName, lastName } = splitName(name);
  return Boolean(firstName.trim() && lastName.trim());
}
export const validateEmail = (email: string, allowEmptyValue: boolean = false) => {
  if(!email) return !!allowEmptyValue;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};
export const validateURL = (url: string, allowEmptyValue: boolean = false) => {
  if (!url) return !!allowEmptyValue;
  const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)/;
  return regex.test(url.toLowerCase());
}
// XTODO this needs to be renamed....
export function toEntity(c: string | number) {
  if (!c) return '';
  return c.toString().replace(/\D/g, '');
}
export function getCurrentEmail(emails: EmailAddress[]) {
  const filtered = emails.filter(x => (isValueless(x.invalid) || !x.invalid) && x.primary);
  let actual = filtered.length ? filtered : emails.filter(x => isValueless(x.invalid) || !x.invalid);
  // For now, if there is no primary email, and no valid email, then we just take all emails
  actual = actual.length ? actual : emails.slice();
  const result = actual.sort((a, b) => {
    const firstDateUpdated = new Date(a.dateUpdated).getTime();
    const secondDateUpdated = new Date(b.dateUpdated).getTime();
    if (firstDateUpdated === secondDateUpdated) {
      return (
        new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      );
    }
    return secondDateUpdated - firstDateUpdated;
  });
  return result.length > 0 ? result[0] : null;
}

export function formatAmount (amount: number) {
  if (!amount) return 0;

  const numberFormat = Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' });
  return numberFormat.format(amount);
}

export function getCurrentCellNumber(phones: Phone[]) {
  if (!phones || !phones.length) return null;
  const cells = phones.filter(p => p.type === PhoneType.Mobile && (isValueless(p.invalid) || !p.invalid) && (isValueless(p.primary) || p.primary))
    .sort((a, b) => {
      const firstDateUpdated = new Date(a.dateUpdated).getTime();
      const secondDateUpdated = new Date(b.dateUpdated).getTime();
      if (firstDateUpdated === secondDateUpdated) {
        return (
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        );
      }
      return secondDateUpdated - firstDateUpdated;
    });
  return cells.length > 0 ? cells[0] : null;
}

export function getCurrentLandlineNumber (phones: Phone[]) {
  if (!phones || !phones.length) return null;
  const landlineNumbers = phones.filter(p => p.type === PhoneType.Landline && (isValueless(p.invalid) || !p.invalid) && (isValueless(p.primary) || p.primary))
    .sort((a, b) => {
      const firstDateUpdated = new Date(a.dateUpdated).getTime();
      const secondDateUpdated = new Date(b.dateUpdated).getTime();
      if (firstDateUpdated === secondDateUpdated) {
        return (
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        );
      }
      return secondDateUpdated - firstDateUpdated;
    });
    return landlineNumbers.length > 0 ? landlineNumbers[0] : null;
}

export function getCurrentAddress(addresses: Address[]) {
  const filtered = addresses.filter(x => (isValueless(x.verified) || x.verified) && (isValueless(x.primary) || x.primary)).sort((a, b) => {
    const firstDateUpdated = new Date(a.dateUpdated).getTime();
    const secondDateUpdated = new Date(b.dateUpdated).getTime();
    if (firstDateUpdated === secondDateUpdated) {
      return (
        new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      );
    }
    return secondDateUpdated - firstDateUpdated;
  });
  return filtered[0] || { street: '', city: '', state: '', zipCode: '', country: '' };
}

export function formatAddressToString(address: Partial <Address> | null | undefined) {
  if (!address) {
    return '';
  }
  const { city, state, country, zipCode, street, street2 } = address;
  return `${street ? ` ${street}` : ''}${street2 ? ` ${street2}` : ''}${city ? ` ${city}` : ''}${state ? ` ${state}` : ''}${zipCode ? ` ${zipCode}` : ''}${country ? ` ${country}` : ''}`.trim();
}

export function getCurrentSeason() {
  const date = new Date();
  const currentYear = date.getMonth() < 7 ? date.getFullYear() : date.getFullYear() + 1;

  return `${currentYear - 1}-${currentYear}`;
}

export function phoneFormat(phone: string | null, countryISO: string = ''): string {
  if (!phone) return '';
  phone = phone.toString();
	const pn = new PhoneNumber(phoneInternational(phone), countryISO || '');
	if (pn.isPossible()) {
    if (pn.getRegionCode() === 'US') {
      return pn.getNumber('national');
		}
    return pn.getNumber('international');
	}

  const part1 = phone.toString().split('').splice(0, 3).join('');
  const part2 = phone.toString().split('').splice(3, 3).join('');
  const part3 = phone.toString().split('').splice(6).join('');

  return `(${part1}) ${part2}-${part3}`;
}

export function phoneInternational(phone: string | null): string {
  if (!phone) {
    return '';
  }
  let phoneNumber = phone.replace(/\D/g, '');
  if (phoneNumber.length === 10 && phoneNumber.startsWith('0')) {
    phoneNumber = phoneNumber.replace(/^0/, '972');
  } else if (phoneNumber.length === 10) {
    phoneNumber = `1${phoneNumber}`;
  }
  return `+${phoneNumber}`;
}

export function cleanPhoneNumber(phone: string | null | undefined) {
  if (!phone){
    return phone;
  }
  return phone.replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/-/g, '');
}

export function dateFormat(ISOdate: string): string | null {
  if (!ISOdate || typeof ISOdate !== 'string' || !ISOdate.includes('T')) return null;
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [year, month, day] = new Date(ISOdate).toISOString().split('T')[0].split('-');
  return monthNames[+month - 1] + ' ' + day + ', ' + year;
}

export function dayOfWeekAndDate (dateString: string) {
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Shabbat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const [year, month, day] = dateString.split('-');

  const date = new Date(+year, +month - 1, +day);

  {
    const dayOfWeek = date.getDay();
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();

    return {
      dayOfWeek: days[dayOfWeek],
      date: `${months[month]} ${day}, ${year}`
    };
  }
}

export function normalizeProps(props = {}) {
  if (Array.isArray(props)) {
    return props.reduce((normalizedProps, prop) => ({ ...normalizedProps, [prop]: {} }), {});
  }

  return { ...props };
}

export function rootFieldFromDocumentNode(query: DocumentNode) {
  const definitionNode = query.definitions.filter(d => d.kind === "OperationDefinition")[0] as OperationDefinitionNode;
  const selectionNode = definitionNode.selectionSet.selections[0] as FragmentSpreadNode;

  return selectionNode.name.value;
}

export function getOperationType(query: DocumentNode) {
  const definitionNode = query.definitions[0] as OperationDefinitionNode;
  return definitionNode.operation;
}

function isFunctional(arg: any): arg is FunctionalComponentOptions {
  return arg.functional !== undefined;
}

export function shortDate(dateUTC: string, timezone: string) {
  if (!dateUTC) return '';
  const date = tz(tz(dateUTC), '%d %b %Y', timezone);
  return date;
}

export const formatAMPM = (date: any) => {
  // stolen from - https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };
export const formatAMPMTo24 = (hour: number, ampm: string): number | null => {
  let hour24format = null;
  if (toLower(ampm) === 'pm') {
    if (hour < 12) {
      hour24format = hour + 12;
    }
    else {
      hour24format = hour;
    }
  }
  else if (toLower(ampm) === 'am') {
    if (hour === 12) {
      hour24format = 0;
    }
    else {
      hour24format = hour;
    }
  }
  return hour24format;
};

export function longDate(dateUTC: string, timezone: string) {
  if (!dateUTC) return '';
  const date = tz(tz(dateUTC), '%A, %B %d', timezone);
  return `${date} At ${formatAMPM(new Date(tz(tz(dateUTC), '%FT%T', timezone)))}`;
}

export function formatNum(arg: number) {
  return arg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getOptions(component: Component): ComponentOptions<Vue> {
  if (typeof component === 'function') {
    return ((component as any) as ComponentOptions<Vue>).options;
  } else {
    if (isFunctional(component)) {
      throw Error('Functional Componens Not Supported');
    }

    return component as ComponentOptions<Vue>;
  }
}

export function injectOptions(component: Component, options: object) {
  const componentOptions = getOptions(component);

  Object.assign(componentOptions, options);
}

export const hasNumber = (myString: string) => {
  return /\d/.test(myString);
}

export function validateNumber (value: string, allowEmptyValue?: boolean) {
    if(!value) return !!allowEmptyValue;
    return /^(\+?)\d+$/.test(value);
}

export function getGrade(year: number | null, includeYearInDescription: boolean = true, schoolYear: number | null = null) {
  if (!year) return { year, grade: '(Unknown)' };

  // Stolen from https://ecommerce.shopify.com/c/ecommerce-design/t/ordinal-number-in-javascript-1st-2nd-3rd-4th-29259
  function ordinal(n: number) {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  schoolYear = schoolYear ? schoolYear :
    new Date().getMonth() > 5 ? new Date().getFullYear() + 1 : new Date().getFullYear();

  const grade = schoolYear - year;

  if (12 + grade > 12) {
    return { year, grade: includeYearInDescription ? year + ' (Alumnus)' : 'Alumnus' };
  }

  let desc = '';
  if (12 + grade > 0) {
    desc = includeYearInDescription ? year + ' (' + ordinal(12 + grade) + ' grade)' : ordinal(12 + grade) + ' grade';
  } else if (12 + grade === 0) {
    desc = includeYearInDescription ? year + ' (Kindergarten)' : 'Kindergarten';
  } else if (12 + grade === -1) {
    desc = includeYearInDescription ? year + ' (Pre-K)' : 'Pre-K';
  }

  return { year, grade: desc };
}
export function getFullName (firstName: string | null, lastName: string | null) {
  if (!firstName) return ''
  return 	startCase(`${trim(firstName || '')} ${trim(lastName || '')}`);
}
export function getAge(DOB: Date) {
  const today = new Date();
  const birthDate = new Date(DOB);
  const m = today.getMonth() - birthDate.getMonth();
  let age = today.getFullYear() - birthDate.getFullYear();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
  }
  return age;
}
export function extractEventValue (object: { [key: string]: any }) {
    let value: any;
    // @ts-ignore
    Object.keys(object).some(k => {
        if (k === 'value') {
            value = object[k];
            return true;
        }
        if (object[k] && typeof object[k] === 'object') {
            value = extractEventValue(object[k]);
            return value !== undefined;
        }
    });
    return value;
}
export function graduationYears() {
  const date = new Date();
  const schoolYear = date.getMonth() > 5 ? date.getFullYear() + 1 : date.getFullYear();
  const highSchool = range(schoolYear, schoolYear + 4).reverse();
  const elementary = range(schoolYear + 4, schoolYear + 14).reverse();
  const alumni = range(schoolYear - 20, schoolYear).reverse();
  const array = highSchool.concat(elementary).concat(alumni);
  return array
  .map(year => ({ year, grade: getGrade(year) }));
}
export function elementaryGrades(includeYearInDescription: boolean = true, schoolYear: number | null = null) {
  const date = new Date();
  schoolYear = schoolYear ? schoolYear : date.getMonth() > 5 ? date.getFullYear() + 1 : date.getFullYear();
  return range(schoolYear + 4, schoolYear + 14)
    .reverse()
    .map(year => ({ ...getGrade(year, includeYearInDescription, schoolYear) }));
}

export function toggleInArray<T>(array: T[], item: T) {
  if (array.includes(item)) {
    const i = array.indexOf(item);
    array.splice(i, 1);
  } else {
    array.push(item);
  }
}

export function validCreditCardYears() {
  return range(new Date().getFullYear(), new Date().getFullYear() + 10);
}

export function splitName(name: string) {
  const result = parser(name);
  return {
    firstName: result.first ? `${result.first}${result.middle ? ` ${result.middle}` : ''}` : '',
    lastName: result.last ? `${result.last}${result.suffix ? ` ${result.suffix}` : ''}` : '',
    original: name
  };
}

interface DateTimeParts {
  date: SimpleDate;
  time: {
    hours: number;
    minutes: number;
  };
}

export function compareDateFromParts(timezone: string, first: DateTimeParts, second: DateTimeParts) {
  if (!first || !second || !first.date || !first.time || !second.date || !second.time) {
    return 0;
  }
  DateTimezone.setGlobalTimezone(timezone);
  const firstDate = new DateTimezone.DateTimezone(
    first.date.year,
    first.date.month - 1,
    first.date.day,
    first.time.hours,
    first.time.minutes
  );
  const secondDate = new DateTimezone.DateTimezone(
    second.date.year,
    second.date.month - 1,
    second.date.day,
    second.time.hours,
    second.time.minutes
  );
  return compareDates(firstDate, secondDate);
}

export function getDayWithSuffix (date: Date | string | SimpleDate): string {
  let _day;
  if (typeof date === 'string') {
    if (isIsoDate(date)) {
      const [ year, month, day ] = map(date.split('T')[0].split('-'), Number);
      _day = new Date(year, month - 1, day).getDate();
    }
    else if (isValidDateYYYMMDDstring(date)) {
      const [ year, month, day ] = map(date.split('-'), Number);
      _day = new Date(year, month - 1, day).getDate();
    }
  }
  else if (date instanceof Date) {
    const [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()];
    _day = new Date(year, month, day).getDate();
  }
  else if (typeof date === 'object' && date.year && date.month && date.day) {
    const { year, month, day } = date;
    _day = new Date(year, month - 1, day).getDate();
  }
  return ordinal(Number(_day)).padStart(4, '0');
}
export function compareDates(first: DateTimezone.DateTimezone, second: DateTimezone.DateTimezone) {
  if (!first || !second || first.valueOf() === second.valueOf()) {
    return 0;
  }

  // This calculation turns the difference between the two dates into either 1 or -1
  return Math.sign(first.valueOf() - second.valueOf());
}
// @ts-ignore
export function convertTo24HourFormat(value: any) {
  if (toLower(value.ampm) === 'am' && +value.hours === 12 )  {
    return 0;
  }

  if (toLower(value.ampm) === 'pm' && +value.hours < 12) {
    return +value.hours + 12;
  }

  return +value.hours;
}

export async function b64ToCSVBlob(b64Data: string) {
  let base64 = atob(b64Data).replace(/"/g, '');
  const base64Response = await fetch(`data:'text/csv';base64,${base64}`);
  const blob = await base64Response.blob();
  return blob;
}
// Adapted from  https://gist.github.com/jdnichollsc/78a6eb093731cf3e8dfd536dbe4befb3
export function b64toBlob(b64Data: string, contentType?: string, headerMetaData?: string, sliceSize?: number) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  headerMetaData = headerMetaData || '';

  const byteCharacters = `${headerMetaData}${atob(b64Data)}`;

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
      type: contentType
  });
  return blob;
}
export function isIsoDate(str: string): boolean {
  if(!str) return false;
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
}
export function isValidDateYYYMMDDstring(dateString: string) {
  if(!dateString || typeof dateString !== 'string') return false;
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false;  // Invalid format
  const d = new Date(dateString);
  const dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
}

export function asUTCWithZerotime(date: Date | string | SimpleDate, returnISOString: boolean = true): string | Date | null {
  let result = null;
  if (!date) return null;
  DateTimezone.setGlobalTimezone('Etc/Greenwich');

  if (typeof date === 'string') {
    if (isIsoDate(date)) {
      const [ year, month, day ] = map(date.split('T')[0].split('-'), Number);
      result = new DateTimezone.DateTimezone(year, month - 1, day, 0, 0);
    }
    else if (isValidDateYYYMMDDstring(date)) {
      const [ year, month, day ] = map(date.split('-'), Number);
      result = new DateTimezone.DateTimezone(year, month - 1, day, 0, 0);
    }
  }
  else if (date instanceof Date) {
    const [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()];
    result = new DateTimezone.DateTimezone(year, month, day, 0, 0);
  }
  else if (typeof date === 'object' && date.year && date.month && date.day) {
    const { year, month, day } = date;
    result = new DateTimezone.DateTimezone(year, month - 1, day, 0, 0);
  }
  return returnISOString ? result && result.toISOString() : result;
}

export function isEventStarted(eventDate: string, timezone: string): boolean {
  if (!eventDate || !timezone || !isIsoDate(eventDate)) return false;
  DateTimezone.setGlobalTimezone(timezone);
  DateTimezone.patch();
  const result = new Date(eventDate) <= new Date();
  DateTimezone.unpatch();
  return result;
}

export function isJsonString(str: string) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export function getZoomTimeZone(timezone: string | null | undefined): string {
  if (!timezone) {
    return 'UTC';
  }
  const timezones: {[k: string]: string} = {
    'America/Anchorage': 'America/Anchorage', // (GMT-09:00) Alaska
    'America/Ensenada': 'America/Tijuana', // (GMT-08:00) Tijuana, Baja California
    'America/Los_Angeles': 'America/Los_Angeles', // (GMT-08:00) Pacific Time (US & Canada)
    'America/Denver': 'America/Denver', // (GMT-07:00) Mountain Time (US & Canada)
    'America/Chihuahua': 'America/Denver', // (GMT-07:00) Chihuahua, La Paz, Mazatlan
    'America/Dawson_Creek': 'America/Phoenix', // (GMT-07:00) Arizona
    'America/Belize': 'America/Regina', // (GMT-06:00) Saskatchewan, Central America
    'America/Cancun': 'America/Mexico_City', // (GMT-06:00) Guadalajara, Mexico City Monterrey
    'America/Chicago': 'America/Chicago', // (GMT-06:00) Central Time (US & Canada)
    'America/New_York': 'America/New_York', // (GMT-05:00) Eastern Time (US & Canada)
    'America/Argentina/Buenos_Aires': 'America/Argentina/Buenos_Aires', // (GMT-03:00) Argentina
    'Chile/Continental': 'America/Santiago', // (GMT-04:00) Continental Chile
    'Asia/Jerusalem': 'Asia/Jerusalem', // (GMT+02:00) Israel
  }
  return timezones[timezone] ? timezones[timezone] : 'UTC';
}

type EnumListOption<T> = { label: string; value: T };
export function enumToList<T extends string>(
  _enum: Record<string, T>
): EnumListOption<T>[] {
  return (
    Object.keys(_enum)
      // .filter(x => Number.isNaN(+x) && filter(x))
      .map((x) => ({
        // Include special case for enums that contain CC
        label:
          _enum[x].toLowerCase() === "cc"
            ? "Credit Card"
            : capitalize(_enum[x]),
        value: _enum[x],
      }))
  );
}
export function enumToListOption<T extends string>(
  option: T
): EnumListOption<T> {
  return {
    label: option.toLowerCase() === "cc" ? "Credit Card" : capitalize(option),
    value: option,
  };
}

export function filterNonPrintable(value: String | null) {
  if (value) {
    return value.replace(/[\x00-\x1F\x7F-\x9F\u200B]/g, '');
  }
  return null;
};
