import { render, staticRenderFns } from "./CustomQuestionsCollections.vue?vue&type=template&id=73b3c32c&scoped=true&"
import script from "./CustomQuestionsCollections.vue?vue&type=script&lang=ts&"
export * from "./CustomQuestionsCollections.vue?vue&type=script&lang=ts&"
import style0 from "./CustomQuestionsCollections.vue?vue&type=style&index=0&id=73b3c32c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73b3c32c",
  null
  
)

export default component.exports