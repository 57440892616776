
import { Vue } from 'vue-property-decorator';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortAlphaDown } from '@fortawesome/pro-solid-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/pro-solid-svg-icons/faSortAlphaUp';
import UISelect from 'shared/ui/forms/FancySelect';
import UIButton from 'shared/ui/buttons/Button.vue';
import fullheight from 'shared/directives/fullheight';
import { Computed, Methods, Props } from './FilterOptions';
import { ScholarshipStatus } from '../types';
import * as RadioGroup from 'shared/radio-group';
import UIRadio from 'shared/ui/forms/Radio.vue';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { isArray } from 'util';
import Divider from 'shared/layout/divider/layout-divider.vue';
import { PermissionEnum, RegistrationPaymentStatus, SortType, StatusType } from 'shared/generated/graphql-types';

library.add(faSortAlphaDown, faSortAlphaUp);

function enumToList(_enum: object, filter: (x: any) => boolean = (x: any) => true) {
  return Object.keys(_enum)
    .filter((x) => Number.isNaN(+x) && filter(x))
    .map((x) => ({
      label: startCase(x),
      value: x
    }));
}

export default Vue.extend<{}, Methods, Computed, Props>({
  name: 'FiltersPanel',
  components: {
    ScrollablePanel,
    UISelect,
    Divider,
    ...RadioGroup,
    UIRadio,
    UIButton
  },
  directives: {
    fullheight
  },
  props: {
    registrations: { type: Array },
    filters: {},
    eventTypeId: { type: Number, default: 0 },
    setFilter: {},
    setFilters: {},
    setSortField: {},
    setSortDescending: {},
    sortDescending: { type: Boolean, default: true },
    sortBy: {},
    claims: {},
  },
  data() {
    return {
      PermissionEnum: PermissionEnum
    };
  },
  computed: {
    sorts() {
      return enumToList(SortType);
    },
    genders() {
      return [
        {
          label: 'Male',
          value: 0
        },
        {
          label: 'Female',
          value: 1
        }
      ];
    },
    registrationStatuses() {
      return enumToList(StatusType).filter(
        (item) => ![StatusType.Cancelled, StatusType.Refunded].includes(item.value as StatusType)
      );
    },
    paymentStatuses() {
      const RegPaymentLabelsToSummerProgramLabels: { [key: string]: string } = {
        Cancelled: 'Cancelled',
        Completed: 'Accepted',
        HasScholarship: 'HasScholarship',
        PendingApproval: 'Incomplete Application',
        RefundNeeded: 'Refund Needed',
        Unpaid: 'Unpaid',
        WaitingForDeposit: 'WaitingForDeposit',
        WaitingList: 'Applied'
      }

      let list = enumToList(
        RegistrationPaymentStatus,
        (r: RegistrationPaymentStatus) =>
          !!this.claims.find((c) => c === PermissionEnum.ViewScholarship) ||
          r !== RegistrationPaymentStatus.HasScholarship
      )

      if (this.eventTypeId === 28) {
        list = list.map((x: any) => ({
          label: RegPaymentLabelsToSummerProgramLabels[x.value],
          value: x.value
        }));
      }

      list.push({label: 'No Payments Made', value: 'NoPaymentsMade'})

      return list
    },
    scholarshipStatuses() {
      return enumToList(ScholarshipStatus);
    },
  },
  methods: {
    getValueFor(collection, value) {
      if (isArray(value)) {
        return collection.filter((c) => value.includes(c.value || toLower(c.value!)));
      }
      return collection.find((x) => toLower(x.value!) === toLower(value));
    },
    clearFilters() {
      const defaultFilters = {
        gender: null,
        paymentStatus: null,
        registrationStatus: null,
        scholarship: null,
      };
      this.setFilters(defaultFilters);
    }
  }
});
